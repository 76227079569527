export const DASHBOARD_SET_ACTIVE_TAB = 'DASHBOARD/SET_ACTIVE_TAB';
export const DASHBOARD_SET_DATE_RANGE = 'DASHBOARD/SET_DATE_RANGE';
export const DASHBOARD_SET_FILTERS = 'DASHBOARD/SET_FILTERS';
export const DASHBOARD_DATEPICKER_TOOGLE = 'DASHBOARD/DATEPICKER_TOOGLE';
export const DASHBOARD_GET_FIRST_TIMERS_GRAPH =
  'DASHBOARD/GET_FIRST_TIMERS_GRAPH';
export const DASHBOARD_GET_ATTENDANCE_GRAPH = 'DASHBOARD/GET_ATTENDANCE_GRAPH';
export const DASHBOARD_GET_MEMBERSHIP_REVENUE_GRAPH =
  'DASHBOARD/GET_MEMBERSHIP_REVENUE_GRAPH';
export const DASHBOARD_GET_ACTIVE_MEMBERSHIP_GRAPH =
  'DASHBOARD/GET_ACTIVE_MEMBERSHIP_GRAPH';
export const DASHBOARD_SET_ASSIDE_DATA_FIRST_TIMERS =
  'DASHBOARD/SET_ASSIDE_DATA_FIRST_TIMERS';
export const DASHBOARD_SET_ASSIDE_DATA_ATTENDANCE =
  'DASHBOARD/SET_ASSIDE_DATA_ATTENDANCE';
export const DASHBOARD_SET_ASSIDE_DATA_ACTIVE_MEMBERSHIPS =
  'DASHBOARD/SET_ASSIDE_DATA_ACTIVE_MEMBERSHIPS';
export const DASHBOARD_SET_ASSIDE_DATA_MEMBERSHIP_REVENUE =
  'DASHBOARD/SET_ASSIDE_DATA_MEMBERSHIP_REVENUE';
export const DASHBOARD_GET_GYMS_PERFORMANCE = 'DASHBOARD/GET_GYMS_PERFORMANCE';
export const DASHBOARD_GET_FIRST_TIMERS_RANKING =
  'DASHBOARD/GET_FIRST_TIMERS_RANKING';
export const DASHBOARD_GET_CLASS_ATTENDANCE_RANKING =
  'DASHBOARD/GET_CLASS_ATTENDANCE_RANKING';
export const DASHBOARD_GET_ACTIVE_MEMBERSHIPS_RANKING =
  'DASHBOARD/GET_ACTIVE_MEMBERSHIPS_RANKING';
export const DASHBOARD_GET_MEMBERSHIPS_REVENUE_RANKING =
  'DASHBOARD/GET_MEMBERSHIPS_REVENUE_RANKING';
