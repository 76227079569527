import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';

import { createAction } from 'redux-actions';
import {
  INCOME_REPORT_CHECK_IF_EXISTS,
  INCOME_REPORT_CONSOLIDATED,
  INCOME_REPORT_CREATE,
  INCOME_REPORT_DELETE,
  INCOME_REPORT_DOWNLOAD,
  INCOME_REPORT_PAGINATED_LIST,
  INCOME_REPORT_RECALCULATE,
  INCOME_REPORT_RESET_CONSOLIDATED,
  INCOME_REPORT_SET_ACTIVE_TAB,
  INCOME_REPORT_SET_FILTERS,
  INCOME_REPORT_SET_SELECTED,
  INCOME_REPORT_SHOW_EDIT_FORM,
  INCOME_REPORT_UPDATE,
} from './types';

export const incomeReportShowEditForm = createAction(
  INCOME_REPORT_SHOW_EDIT_FORM
);
export const incomeReportResetConsolidated = createAction(
  INCOME_REPORT_RESET_CONSOLIDATED
);
export const incomeReportSetActiveTab = createAction(
  INCOME_REPORT_SET_ACTIVE_TAB
);
export const incomeReportSetFilters = createAction(INCOME_REPORT_SET_FILTERS);

export const incomeReportSetSelectedReport = createAction(
  INCOME_REPORT_SET_SELECTED
);

export const createIncomeReport = createRoutine(INCOME_REPORT_CREATE);
export const recalculateIncomeReport = createRoutine(INCOME_REPORT_RECALCULATE);
export const updateIncomeReport = createRoutine(INCOME_REPORT_UPDATE);
export const deleteIncomeReport = createRoutine(INCOME_REPORT_DELETE);
export const downloadIncomeReport = createRoutine(INCOME_REPORT_DOWNLOAD);
export const checkIfExistsIncomeReport = createRoutine(
  INCOME_REPORT_CHECK_IF_EXISTS
);
export const getPaginatedListIncomeReport = createRoutine(
  INCOME_REPORT_PAGINATED_LIST
);
export const getConsolidatedIncomeReport = createRoutine(
  INCOME_REPORT_CONSOLIDATED
);
export const bindedCreateIncomeReport =
  bindRoutineToReduxForm(createIncomeReport);
export const bindedUpdateIncomeReport =
  bindRoutineToReduxForm(updateIncomeReport);
