import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';

import { createAction } from 'redux-actions';
import {
  RESET_SELECTED_USER,
  SET_SELECTED_USER,
  USER_CREATE,
  USER_DELETE,
  USER_GET,
  USER_LIST,
  USER_UPDATE,
  USER_UPDATE_STATUS,
} from './types';

export const listUsers = createRoutine(USER_LIST);
export const getUser = createRoutine(USER_GET);
export const updateUser = createRoutine(USER_UPDATE);
export const resetSelectedUser = createAction(RESET_SELECTED_USER);
export const setSelectedUser = createAction(SET_SELECTED_USER);
export const updateUserStatus = createRoutine(USER_UPDATE_STATUS);
export const createUSer = createRoutine(USER_CREATE);
export const deleteUser = createRoutine(USER_DELETE);
export const bindedUpdateUser = bindRoutineToReduxForm(updateUser);
