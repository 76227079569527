export const LIST_DISCOUNTS = 'LIST_DISCOUNTS';
export const ADD_DISCOUNT = 'ADD_DISCOUNT';
export const UPDATE_DISCOUNT = 'UPDATE_DISCOUNT';
export const LIST_MEMBER_METHODS = 'LIST_MEMBER_METHODS';
export const LIST_MEMBER_TYPES = 'LIST_MEMBER_TYPES';
export const LIST_COUPONS = 'LIST_COUPONS';
export const ADD_COUPON = 'ADD_COUPON';
export const UPDATE_COUPON = 'UPDATE_COUPON';
export const LIST_SKUS = 'LIST_SKUS';
export const ADD_SKU = 'ADD_SKU';
export const UPDATE_SKU = 'UPDATE_SKU';
export const LIST_SKU_CATEGORIES = 'LIST_SKU_CATEGORIES';
export const LIST_MEMBER_TYPES_METHODS = 'LIST_MEMBER_TYPES_METHODS';
export const LIST_PRICELISTS = 'LIST_PRICELISTS';
export const UPDATE_PRICELIST = 'UPDATE_PRICELIST';
export const LIST_PACKAGES = 'LIST_PACKAGES';
export const ADD_PACKAGE = 'ADD_PACKAGE';
export const UPDATE_PACKAGE = 'UPDATE_PACKAGE';
export const LIST_PACKAGE_DETAIL = 'LIST_PACKAGE_DETAIL';
export const ADD_PACKAGE_DETAIL = 'ADD_PACKAGE_DETAIL';
export const UPDATE_PACKAGE_DETAIL = 'UPDATE_PACKAGE_DETAIL';
