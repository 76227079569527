import { all, call, put, takeLatest } from 'redux-saga/effects';

import SDK from 'utils/sdk';
import http from 'utils/http';
import parseData from 'utils/parse-data';
import {
  createSchedule,
  downloadReport,
  listReportLog,
  listReports,
  listScheduled,
  runReport,
  updateSchedule,
} from './actions';

export function* listReportsSaga() {
  try {
    yield put(listReports.request());
    const client = yield call([SDK, 'getClient']);
    const { data: response } = yield call([client, 'reportList']);

    if (response.success) {
      yield put(listReports.success(response.data));
    } else {
      yield put(listReports.failure(response.error));
    }
  } catch (error) {
    yield put(listReports.failure(error));
  } finally {
    yield put(listReports.fulfill());
  }
}

export function* listReportsScheduledSaga() {
  try {
    yield put(listScheduled.request());
    const { data } = yield call(http, 'wwGetReportSched');
    if (data.success) {
      yield put(listScheduled.success(data.data));
    } else {
      yield put(listScheduled.failure(data.advisory));
    }
  } catch (error) {
    yield put(listScheduled.failure(error));
  } finally {
    yield put(listScheduled.fulfill());
  }
}

export function* listReportsLogSaga() {
  try {
    yield put(listReportLog.request());

    const client = yield call([SDK, 'getClient']);
    const { data } = yield call([client, 'recordList']);

    yield put(listReportLog.success(data));
  } catch (error) {
    const data = error?.response?.data?.message?.toString();
    yield put(listReportLog.failure(data));
  } finally {
    yield put(listReportLog.fulfill());
  }
}

export function* runReportSaga({ payload: { values } }) {
  try {
    yield put(runReport.request());
    const { path, ...requestBody } = values;
    if (requestBody.additionalRevenues) {
      const normalizedData = Object.keys(requestBody.additionalRevenues).map(
        (key) => {
          return {
            locationId: key.replace('location_', ''),
            additionalRevenue: requestBody.additionalRevenues[key],
          };
        }
      );
      Object.assign(requestBody, {
        ...requestBody,
        additionalRevenues: normalizedData,
      });
    }
    const client = yield call([SDK, 'getClient']);
    const { data } = yield call([client, path], null, requestBody);
    if (data.success) {
      const { report } = data;
      yield put(downloadReport({ report }));
      yield put(runReport.success());
    } else {
      yield put(runReport.failure(data.advisory));
    }
  } catch (error) {
    yield put(runReport.failure(error));
  } finally {
    yield put(runReport.fulfill());
  }
}

export function* downloadReportSaga({ payload: { report } }) {
  try {
    yield put(downloadReport.request());
    window.open(report, '_blank');
    yield put(downloadReport.success());
  } catch (error) {
    yield put(downloadReport.failure(error));
  } finally {
    yield put(downloadReport.fulfill());
  }
}

export function* createScheduleSaga({ payload: { values } }) {
  try {
    yield put(createSchedule.request());
    const formData = parseData(values);
    const { data } = yield call(http, 'wwNewReportSched', 'post', formData);
    if (data.success) {
      yield put(createSchedule.success(data.data));
    } else {
      yield put(createSchedule.failure(data.advisory));
    }
  } catch (error) {
    yield put(createSchedule.failure(error));
  } finally {
    yield put(createSchedule.fulfill());
  }
}

export function* updateScheduleSaga({ payload: { values } }) {
  try {
    yield put(updateSchedule.request());
    const formData = parseData(values);
    const { data } = yield call(http, 'wwUpdReportSched', 'post', formData);
    if (data.success) {
      yield put(updateSchedule.success(data.data));
    } else {
      yield put(updateSchedule.failure(data.advisory));
    }
  } catch (error) {
    yield put(updateSchedule.failure(error));
  } finally {
    yield put(updateSchedule.fulfill());
  }
}

export default function* reportsWatcher() {
  yield all([
    takeLatest(listReports.TRIGGER, listReportsSaga),
    takeLatest(listScheduled.TRIGGER, listReportsScheduledSaga),
    takeLatest(listReportLog.TRIGGER, listReportsLogSaga),
    takeLatest(runReport.TRIGGER, runReportSaga),
    takeLatest(createSchedule.TRIGGER, createScheduleSaga),
    takeLatest(updateSchedule.TRIGGER, updateScheduleSaga),
    takeLatest(downloadReport.TRIGGER, downloadReportSaga),
  ]);
}
