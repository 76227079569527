import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { formatOutgoingHours } from 'utils/format/std-hours';
// import camelcaseKeys from 'camelcase-keys';
// import { retrieveMe } from 'modules/me/actions';
import { getGymItem } from 'modules/gyms/selectors';
import http from 'utils/http';
import parseData from 'utils/parse-data';
import { listStandardHours, updateStandardHours } from './actions';

export function* listStandardHoursSaga() {
  try {
    yield put(listStandardHours.request());
    const { data } = yield call(http, 'GetGymSched');
    if (data.success) {
      yield put(listStandardHours.success(data.data));
    } else {
      yield put(listStandardHours.failure(data.advisory));
    }
  } catch (error) {
    yield put(listStandardHours.failure(error));
  } finally {
    yield put(listStandardHours.fulfill());
  }
}

export function* updateStandardHoursSaga({ payload: { values } }) {
  try {
    const currentGym = yield select(getGymItem);
    const newValues = formatOutgoingHours(values);
    newValues.StdScheduleGym = currentGym;
    const formData = parseData(newValues);
    yield put(updateStandardHours.request());
    // const { data } = yield call(http, 'SetStdHours', 'post', formData, false);
    yield call(http, 'SetStdHours', 'post', formData, false);
    /**
     * Need a response from the endpoint to check succes!
     */
    // if (data) {
    yield put(updateStandardHours.success());
    // } else {

    //   yield put(updateStandardHours.failure(data.advisory));
    // }
  } catch (error) {
    yield put(updateStandardHours.failure(error));
  } finally {
    yield put(updateStandardHours.fulfill());
  }
}

export default function* stdHoursWatcher() {
  yield all([
    takeLatest(listStandardHours.TRIGGER, listStandardHoursSaga),
    takeLatest(updateStandardHours.TRIGGER, updateStandardHoursSaga),
  ]);
}
