import {
  dashboardGetActiveMembershipsGraph,
  dashboardGetActiveMembershipsRanking,
  dashboardGetAttendanceGraph,
  dashboardGetClassAttendanceRanking,
  dashboardGetFirstTimersGraph,
  dashboardGetFirstTimersRanking,
  dashboardGetMembershipRevenueGraph,
  dashboardGetMembershipsRevenueRanking,
  dashboardGetgymsPerformance,
} from 'modules/dashboards/actions';
import {
  listCoupons,
  listDiscounts,
  listMemberMethods,
  listMemberTypes,
  listMemberTypesMethods,
  listPackages,
  listPricelists,
  listSkus,
  listSkusCategories,
} from 'modules/sku-manager/actions';
import {
  listReportLog,
  listReports,
  listScheduled,
} from 'modules/reports/actions';

import Loadable from 'react-loadable';
import ScreenLoading from 'views/ui/screen-loading';
import { getPaginatedListIncomeReport } from 'modules/income-report/actions';
import { listHolidayHours } from 'modules/holiday-hours/actions';
import { listMeGyms } from 'modules/gyms/actions';
import { listRoles } from 'modules/roles/actions';
import { listStandardHours } from 'modules/std-hours/actions';
import { listUsers } from 'modules/users/actions';

const Root = Loadable({
  loader: () => import('views/screens/login'),
  loading: ScreenLoading,
});

const ResetPassword = Loadable({
  loader: () => import('views/screens/reset-password'),
  loading: ScreenLoading,
});

const Welcome = Loadable({
  loader: () => import('views/screens/welcome'),
  loading: ScreenLoading,
});

const ContainerDashboard = Loadable({
  loader: () => import('views/containers/dashboard'),
  loading: ScreenLoading,
});

const StandardSchedule = Loadable({
  loader: () => import('views/screens/schedule-manager/standard-schedule'),
  loading: ScreenLoading,
});

const HolidaySchedule = Loadable({
  loader: () => import('views/screens/schedule-manager/holiday-schedule'),
  loading: ScreenLoading,
});

const UserManager = Loadable({
  loader: () => import('views/screens/user-manager'),
  loading: ScreenLoading,
});

const Discounts = Loadable({
  loader: () => import('views/screens/sku-manager/discounts'),
  loading: ScreenLoading,
});

const Coupons = Loadable({
  loader: () => import('views/screens/sku-manager/coupons'),
  loading: ScreenLoading,
});

const SKU = Loadable({
  loader: () => import('views/screens/sku-manager/skus'),
  loading: ScreenLoading,
});

const Packages = Loadable({
  loader: () => import('views/screens/sku-manager/packages'),
  loading: ScreenLoading,
});

const Pricelists = Loadable({
  loader: () => import('views/screens/sku-manager/pricelists'),
  loading: ScreenLoading,
});

const TypesMethods = Loadable({
  loader: () => import('views/screens/sku-manager/member-manager'),
  loading: ScreenLoading,
});

const MyReports = Loadable({
  loader: () => import('views/screens/reports/my-reports'),
  loading: ScreenLoading,
});

const ReportGeneration = Loadable({
  loader: () => import('views/screens/reports/report-generate'),
  loading: ScreenLoading,
});

const ReportScheduler = Loadable({
  loader: () => import('views/screens/reports/report-schedule'),
  loading: ScreenLoading,
});

const EditReportScheduler = Loadable({
  loader: () => import('views/screens/reports/edit-report-schedule'),
  loading: ScreenLoading,
});

const SettingsByLocation = Loadable({
  loader: () => import('views/screens/settings/location'),
  loading: ScreenLoading,
});

const Dashboard = Loadable({
  loader: () => import('views/screens/dashboard'),
  loading: ScreenLoading,
});

const IncomeReport = Loadable({
  loader: () => import('views/screens/income-report'),
  loading: ScreenLoading,
});

const routes = [
  {
    path: '/',
    component: Root,
    exact: true,
    published: true,
  },
  {
    path: '/login',
    component: Root,
    exact: true,
    published: true,
  },
  {
    path: '/reset-password/:token',
    component: ResetPassword,
    exact: true,
    published: true,
  },
  {
    path: '/app',
    component: ContainerDashboard,
    privated: true,
    actions: [listMeGyms],
    routes: [
      {
        path: '/app',
        component: Welcome,
        exact: true,
      },
      {
        path: '/app/dashboard',
        component: Dashboard,
        exact: true,
        actions: [
          listMeGyms,
          dashboardGetFirstTimersGraph,
          dashboardGetAttendanceGraph,
          dashboardGetMembershipRevenueGraph,
          dashboardGetActiveMembershipsGraph,
          dashboardGetgymsPerformance,
          dashboardGetFirstTimersRanking,
          dashboardGetClassAttendanceRanking,
          dashboardGetMembershipsRevenueRanking,
          dashboardGetActiveMembershipsRanking,
        ],
      },
      {
        path: '/app/gym-hours/standard',
        component: StandardSchedule,
        exact: true,
        actions: [listStandardHours],
      },
      {
        path: '/app/gym-hours/holiday',
        component: HolidaySchedule,
        exact: true,
        actions: [listHolidayHours],
      },
      {
        path: '/app/user-manager',
        component: UserManager,
        exact: true,
        actions: [listUsers, listRoles, listMeGyms],
      },
      {
        path: '/app/sku-manager/discounts',
        component: Discounts,
        exact: true,
        actions: [listDiscounts],
      },
      {
        path: '/app/sku-manager/coupons',
        component: Coupons,
        exact: true,
        actions: [
          listCoupons,
          listDiscounts,
          listMemberMethods,
          listMemberTypes,
        ],
      },
      {
        path: '/app/sku-manager/skus',
        component: SKU,
        exact: true,
        actions: [listSkus, listSkusCategories, listPackages],
      },
      {
        path: '/app/sku-manager/packages',
        component: Packages,
        exact: true,
        actions: [listPackages, listSkus, listSkusCategories, listDiscounts],
      },
      {
        path: '/app/sku-manager/pricelist',
        component: Pricelists,
        exact: true,
        actions: [listPricelists],
      },
      {
        path: '/app/sku-manager/members-t&m/',
        component: TypesMethods,
        exact: true,
        actions: [listMemberTypesMethods],
      },
      {
        path: '/app/reports/list/',
        component: MyReports,
        exact: true,
        actions: [listReportLog, listReports],
      },
      {
        path: '/app/reports/generate/',
        component: ReportGeneration,
        exact: true,
        actions: [listReports, listMeGyms],
      },
      {
        path: '/app/reports/schedule/',
        component: ReportScheduler,
        exact: true,
        actions: [listScheduled, listReports],
      },
      {
        path: '/app/reports/schedule/edit',
        component: EditReportScheduler,
        exact: true,
      },
      {
        path: '/app/reports/income-report',
        component: IncomeReport,
        exact: true,
        actions: [listMeGyms, getPaginatedListIncomeReport],
      },
      {
        path: '/app/settings/location/',
        component: SettingsByLocation,
        exact: true,
      },
    ],
  },
  {
    name: '404',
    path: '*',
    component: () => null,
  },
];

export default routes;
