import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  tooglListModal,
  toogleExistsIncomeReportModal,
} from 'modules/modal/actions';

import { INCOME_PERIODS } from 'utils/periods';
import SDK from 'utils/sdk';
import { SubmissionError } from 'redux-form';
import { parseISO } from 'date-fns';
import {
  getIncomeReportFilters,
  getSelectedConsolidatedIncomeReport,
} from './selectors';
import {
  checkIfExistsIncomeReport,
  createIncomeReport,
  deleteIncomeReport,
  downloadIncomeReport,
  getConsolidatedIncomeReport,
  getPaginatedListIncomeReport,
  incomeReportSetActiveTab,
  incomeReportSetFilters,
  incomeReportShowEditForm,
  recalculateIncomeReport,
  updateIncomeReport,
} from './actions';

function* createIncomeReportSaga({ payload: { values } }) {
  try {
    const { location, date, ...rest } = values;
    const locationId = location.value;
    yield put(createIncomeReport.request());
    const client = yield call([SDK, 'getClient']);
    const response = yield call(
      [client, 'incomeReportCreate'],
      {},
      {
        locationId,
        date: date.toISOString({}),
        ...rest,
      }
    );
    if (response.status === 201) {
      yield put(
        incomeReportSetFilters({
          locations: [location],
          dateRange: { startDate: date, endDate: date },
          type: { value: INCOME_PERIODS[0], label: INCOME_PERIODS[1] },
        })
      );
      yield put(incomeReportSetActiveTab(1));
      yield put(getConsolidatedIncomeReport());
      yield put(createIncomeReport.success());
    }
  } catch (error) {
    const data = error?.response?.data?.message?.toString();
    yield put(
      createIncomeReport.failure(
        new SubmissionError(new SubmissionError({ _error: data }))
      )
    );
  } finally {
    yield put(createIncomeReport.fulfill());
  }
}

function* updateIncomeReportSaga({ payload: { values } }) {
  try {
    const { id } = yield select(getSelectedConsolidatedIncomeReport);
    const { location, date, ...rest } = values;
    yield put(updateIncomeReport.request());
    const client = yield call([SDK, 'getClient']);
    const response = yield call(
      [client, 'incomeReportUpdate'],
      { id },
      {
        ...rest,
      }
    );
    if (response.status === 200) {
      yield put(
        incomeReportSetFilters({
          locations: [location],
          dateRange: { startDate: date, endDate: date },
          type: { value: INCOME_PERIODS[0], label: INCOME_PERIODS[1] },
        })
      );
      yield put(incomeReportSetActiveTab(1));
      yield put(incomeReportShowEditForm(false));
      yield put(getConsolidatedIncomeReport());
      yield put(updateIncomeReport.success());
    }
  } catch (error) {
    const data = error?.response?.data?.message?.toString();
    yield put(
      updateIncomeReport.failure(
        new SubmissionError(new SubmissionError({ _error: data }))
      )
    );
  } finally {
    yield put(updateIncomeReport.fulfill());
  }
}

function* deleteIncomeReportSaga() {
  try {
    const { id } = yield select(getSelectedConsolidatedIncomeReport);
    yield put(deleteIncomeReport.request());
    const client = yield call([SDK, 'getClient']);
    yield call([client, 'incomeReportDelete'], {
      id,
    });
    yield put(getPaginatedListIncomeReport({ page: 1, limit: 10 }));
    yield put(deleteIncomeReport.success());
  } catch (error) {
    const data = error?.response?.data?.message?.toString();
    yield put(
      deleteIncomeReport.failure(
        new SubmissionError(new SubmissionError({ _error: data }))
      )
    );
  } finally {
    yield put(deleteIncomeReport.fulfill());
  }
}

function* downloadIncomeReportSaga({ payload }) {
  try {
    yield put(downloadIncomeReport.request());
    const client = yield call([SDK, 'getClient']);
    const response = yield call(
      [client, 'incomeReportDownload'],
      {
        ...payload,
      },
      {},
      { responseType: 'blob' }
    );
    const fileName = 'downloaded-file.xlsx';

    const blob = response.data;
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    yield put(downloadIncomeReport.success());
  } catch (error) {
    const data = error?.response?.data?.message?.toString();
    yield put(
      downloadIncomeReport.failure(
        new SubmissionError(new SubmissionError({ _error: data }))
      )
    );
  } finally {
    yield put(downloadIncomeReport.fulfill());
  }
}

function* checkIfExistsIncomeReportSagas({ payload }) {
  const { location, date } = payload;
  try {
    yield put(checkIfExistsIncomeReport.request());
    const client = yield call([SDK, 'getClient']);
    const response = yield call([client, 'incomeReportCheckIfExists'], {
      locationId: location.value,
      date: date.toISOString(),
    });
    if (response.status === 200) {
      const { data } = response;
      if (data.exists) {
        yield put(toogleExistsIncomeReportModal(true));
      }
      yield put(
        checkIfExistsIncomeReport.success(
          { ...data.report, date: parseISO(data.report.date) } || null
        )
      );
    }
  } catch (error) {
    const data = error?.response?.data?.message?.toString();
    yield put(
      checkIfExistsIncomeReport.failure(
        new SubmissionError(new SubmissionError({ _error: data }))
      )
    );
  } finally {
    yield put(checkIfExistsIncomeReport.fulfill());
  }
}

function* getPaginatedListIncomeReportSagas({ payload: { page, limit } }) {
  try {
    const {
      locations,
      dateRange: { startDate, endDate },
    } = yield select(getIncomeReportFilters);
    yield put(getPaginatedListIncomeReport.request());
    const client = yield call([SDK, 'getClient']);
    const { data } = yield call(
      [client, 'incomeReportList'],
      {
        page,
        limit,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
        locationIds: locations.map(({ value }) => value),
      },
      {}
    );

    yield put(getPaginatedListIncomeReport.success(data));
  } catch (error) {
    yield put(getPaginatedListIncomeReport.failure(error));
  } finally {
    yield put(getPaginatedListIncomeReport.fulfill());
  }
}
function* getConsolidatedIncomeReportSagas() {
  try {
    const {
      locations,
      dateRange: { startDate, endDate },
    } = yield select(getIncomeReportFilters);
    yield put(getConsolidatedIncomeReport.request());
    const locationIds = locations.map(({ value }) => value);
    const client = yield call([SDK, 'getClient']);
    const response = yield call(
      [client, 'incomeReportConsolidated'],
      {
        locationIds,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
      {}
    );
    if (response.status === 200) {
      const { data } = response;
      yield put(getConsolidatedIncomeReport.success(data));
    } else {
      yield put(getConsolidatedIncomeReport.failure(response?.response?.data));
      yield put(tooglListModal(true));
    }
  } catch (error) {
    yield put(getConsolidatedIncomeReport.failure(error));
  } finally {
    yield put(getConsolidatedIncomeReport.fulfill());
  }
}

function* recalculateIncomeReportSaga() {
  try {
    const { id, ...rest } = yield select(getSelectedConsolidatedIncomeReport);
    yield put(recalculateIncomeReport.request());
    const client = yield call([SDK, 'getClient']);
    const response = yield call([client, 'incomeReportRecalculate'], { id });
    if (response.status === 200) {
      yield put(
        incomeReportSetFilters({
          locations: [{ label: rest.location.name, value: rest.location.id }],
          dateRange: { startDate: rest.date, endDate: rest.date },
          type: { value: INCOME_PERIODS[0], label: INCOME_PERIODS[1] },
        })
      );
      yield put(getConsolidatedIncomeReport());
      yield put(recalculateIncomeReport.success());
    }
  } catch (error) {
    const data = error?.response?.data?.message?.toString();
    yield put(
      recalculateIncomeReport.failure(
        new SubmissionError(new SubmissionError({ _error: data }))
      )
    );
  } finally {
    yield put(recalculateIncomeReport.fulfill());
  }
}

export default function* incomeReportWatcher() {
  yield all([takeLatest(createIncomeReport.TRIGGER, createIncomeReportSaga)]);
  yield all([takeLatest(updateIncomeReport.TRIGGER, updateIncomeReportSaga)]);
  yield all([takeLatest(deleteIncomeReport.TRIGGER, deleteIncomeReportSaga)]);
  yield all([
    takeLatest(downloadIncomeReport.TRIGGER, downloadIncomeReportSaga),
  ]);
  yield all([
    takeLatest(
      checkIfExistsIncomeReport.TRIGGER,
      checkIfExistsIncomeReportSagas
    ),
  ]);
  yield all([
    takeLatest(
      getPaginatedListIncomeReport.TRIGGER,
      getPaginatedListIncomeReportSagas
    ),
    takeLatest(
      getConsolidatedIncomeReport.TRIGGER,
      getConsolidatedIncomeReportSagas
    ),
    takeLatest(recalculateIncomeReport.TRIGGER, recalculateIncomeReportSaga),
  ]);
}
