import { applyMiddleware, compose, createStore } from 'redux';
import { createTransform, persistReducer, persistStore } from 'redux-persist';

import { createAbilityForUser } from 'utils/casl/ability-utils';
import createSagaMiddleware from 'redux-saga';
import localForage from 'localforage';
import { routerMiddleware } from 'connected-react-router';
import history from './history';
import reducer from './reducer';
// eslint-disable-next-line import/no-cycle
import saga from './saga';

const sagaMiddleware = createSagaMiddleware();

// If Redux DevTools Extension is installed use it, otherwise use Redux compose
const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  // @ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? // @ts-ignore
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ shouldHotReload: false })
    : compose;

localForage.config({
  name: 'Sweat440admin',
  storeName: 'sweat440admin',
});

const SetTransform = createTransform(
  (inboundState) => {
    return inboundState;
  },
  (outboundState) => {
    const ability = outboundState.ability
      ? createAbilityForUser(outboundState.ability.j)
      : null;
    return { ...outboundState, ability };
  },
  { whitelist: ['auth'] }
);

const persistConfig = {
  key: 'root',
  version: 0,
  storage: localForage,
  debug: process.env.NODE_ENV !== 'production',
  whitelist: [
    'auth',
    'me',
    'gyms',
    'sku',
    'users',
    'roles',
    'stdHours',
    'holidayHours',
    'reports',
    'settings',
  ],
  transforms: [SetTransform],
};

const middlewares = [sagaMiddleware, routerMiddleware(history)];

const enhancers = [applyMiddleware(...middlewares)];

const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(persistedReducer, composeEnhancers(...enhancers));

const persistor = persistStore(store);

sagaMiddleware.run(saga);

export { store, persistor };
