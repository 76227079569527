import { INCOME_PERIODS } from 'utils/periods';
/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  checkIfExistsIncomeReport,
  getConsolidatedIncomeReport,
  getPaginatedListIncomeReport,
  incomeReportResetConsolidated,
  incomeReportSetActiveTab,
  incomeReportSetFilters,
  incomeReportSetSelectedReport,
  incomeReportShowEditForm,
  recalculateIncomeReport,
} from './actions';

const initialState = {
  activeTab: 0,
  reports: [],
  paginationMetaData: {
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 0,
    totalPages: 0,
    currentPage: 0,
  },
  consolidatedReport: null,
  selectedReport: null,
  filters: {
    type: { label: INCOME_PERIODS[0], value: INCOME_PERIODS[0] },
    dateRange: {
      startDate: null,
      endDate: null,
    },
    locations: [],
  },
  showEditForm: false,
  missedReports: [],
  loading: false,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case recalculateIncomeReport.REQUEST:
    case getConsolidatedIncomeReport.REQUEST:
    case getPaginatedListIncomeReport.REQUEST:
      draft.loading = true;
      break;
    case getConsolidatedIncomeReport.SUCCESS:
      draft.consolidatedReport = payload;
      draft.missedReports = [];
      break;
    case getConsolidatedIncomeReport.FAILURE:
      if (payload.missedReports) {
        draft.missedReports = payload.missedReports;
      }
      draft.consolidatedReport = null;
      break;
    case getPaginatedListIncomeReport.SUCCESS:
      draft.reports = payload.items;
      draft.paginationMetaData = payload.meta;
      break;
    case checkIfExistsIncomeReport.SUCCESS:
      draft.selectedReport = payload;
      break;
    case incomeReportSetActiveTab.toString():
      draft.activeTab = payload;
      break;
    case incomeReportResetConsolidated.toString():
      draft.consolidatedReport = null;
      break;
    case incomeReportSetSelectedReport.toString():
      draft.selectedReport = payload;
      break;
    case incomeReportSetFilters.toString():
      draft.filters = { ...draft.filters, ...payload };
      break;
    case incomeReportShowEditForm.toString():
      draft.showEditForm = payload;
      break;
    case getConsolidatedIncomeReport.FULFILL:
    case getPaginatedListIncomeReport.FULFILL:
      draft.loading = false;
      break;
  }
}, initialState);

export default reducer;
