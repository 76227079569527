/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { listHolidayHours } from './actions';
import { ADD_HOLIDAY } from './types';

const initialState = {
  error: null,
  items: [],
  loading: false,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case listHolidayHours.TRIGGER:
      draft.loading = true;
      draft.error = null;
      break;
    case listHolidayHours.SUCCESS:
      draft.items = payload;
      break;
    case ADD_HOLIDAY:
      draft.items.unshift(payload);
      break;
    case listHolidayHours.FAILURE:
      draft.error = payload;
      break;
    case listHolidayHours.FULFILL:
      draft.loading = false;
      break;
  }
}, initialState);

export default reducer;
