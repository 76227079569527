import axios from 'axios';

// eslint-disable-next-line prefer-const
let { REACT_APP_API_HOST, NODE_ENV } = process.env;

if (NODE_ENV === 'production') {
  REACT_APP_API_HOST = `https://${window.location.hostname}/s/fr.dll`;
}

export default (
  path,
  method = 'get',
  data = null,
  params = null,
  withCredentials = true,
  headers = {},
  responseType = 'json',
  host = REACT_APP_API_HOST
) => {
  const url = `${host}/${path}`;
  // @ts-ignore
  return axios({
    method,
    url,
    data,
    params,
    headers,
    withCredentials,
    responseType,
  });
};
