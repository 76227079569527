/* eslint-disable no-case-declarations */
import {
  endOfQuarter,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  sub,
  subQuarters,
} from 'date-fns';

export const PERIODS = [
  'BY PERIOD',
  'MONTH TO DATE',
  'YEAR TO DATE',
  'LAST 7 DAYS',
  'LAST 30 DAYS',
  'LAST QUARTER',
];
export const INCOME_PERIODS = ['BY DATE RANGE', 'BY MONTH'];

export const ConverPeriodInDateRange = (period) => {
  const today = new Date();
  const date = {
    startDate: sub(today, { years: 1 }),
    endDate: today,
    key: 'selection',
  };

  switch (period) {
    case 'MONTH TO DATE':
      date.startDate = startOfMonth(today);
      return date;
    case 'YEAR TO DATE':
      date.startDate = startOfYear(today);
      return date;
    case 'LAST 7 DAYS':
      date.startDate = sub(today, { days: 7 });
      return date;
    case 'LAST 30 DAYS':
      date.startDate = sub(today, { days: 30 });
      return date;
    case 'LAST QUARTER':
      // Resta 1 cuarto de año a la fecha actual
      const lastQuarter = subQuarters(today, 1);
      // Obtén el inicio y el final del último cuarto de año
      const start = startOfQuarter(lastQuarter);
      const end = endOfQuarter(lastQuarter);
      date.startDate = start;
      date.endDate = end;
      return date;
    default:
      return date;
  }
};
