/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { listStandardHours } from './actions';

const initialState = {
  error: null,
  items: [],
  loading: false,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case listStandardHours.TRIGGER:
      draft.loading = true;
      draft.error = null;
      break;
    case listStandardHours.SUCCESS:
      draft.items = payload;
      break;
    case listStandardHours.FAILURE:
      draft.error = payload;
      break;
    case listStandardHours.FULFILL:
      draft.loading = false;
      break;
  }
}, initialState);

export default reducer;
