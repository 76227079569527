/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { sub } from 'date-fns';
import {
  dashboardGetActiveMembershipsGraph,
  dashboardGetActiveMembershipsRanking,
  dashboardGetAttendanceGraph,
  dashboardGetClassAttendanceRanking,
  dashboardGetFirstTimersGraph,
  dashboardGetFirstTimersRanking,
  dashboardGetMembershipRevenueGraph,
  dashboardGetMembershipsRevenueRanking,
  dashboardGetgymsPerformance,
  dashboardSetActiveTab,
  dashboardSetAssideDataActiveMemberships,
  dashboardSetAssideDataAttendance,
  dashboardSetAssideDataFirstTimers,
  dashboardSetAssideDataMembershipsRevenue,
  dashboardSetFilters,
  dashboardToogleDatePicker,
} from './actions';

const initialAssideData = {
  total: {
    value: 0,
    label: 'This Month',
  },
  growth: {
    value: 0,
    label: 'Last Month',
    increase: true,
  },
};

const initialState = {
  activeTab: 0,
  filters: {
    dateRange: {
      startDate: sub(sub(new Date(), { days: 1 }), { years: 1 }),
      endDate: sub(new Date(), { days: 1 }),
      key: 'selection',
    },
    locations: [],
  },
  showDatepickerPopover: false,
  loadingFirstTimersGraphData: false,
  loadingAttendanceGraphData: false,
  loadingMembershipRevenueGraphData: false,
  loadingActiveMembershipsGraphData: false,
  firstTimersGraphData: {
    step: '',
    graphDatasets: [],
  },
  attendanceGraphData: {
    step: '',
    graphDatasets: [],
  },
  membershipRevenueGraphData: {
    step: '',
    graphDatasets: [],
  },
  activeMembershipsGraphData: {
    step: '',
    graphDatasets: [],
  },
  assideFirstTimersGraph: initialAssideData,
  assideAttendanceGraph: initialAssideData,
  assideMembershipRevenueGraph: initialAssideData,
  assideActiveMembershipsGraph: initialAssideData,
  gymsPerformance: {
    doingWell: [],
    doingBad: [],
  },
  firstTimersRanking: [],
  classAttendanceRanking: [],
  activeMembershipsRanking: [],
  membershipsRevenueRanking: [],
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case dashboardSetActiveTab.toString():
      draft.activeTab = payload;
      break;
    case dashboardSetFilters.toString():
      draft.filters = { ...draft.filters, ...payload };
      break;
    case dashboardToogleDatePicker.toString():
      draft.showDatepickerPopover = payload;
      break;
    case dashboardSetAssideDataFirstTimers.toString():
      draft.assideFirstTimersGraph = payload;
      break;
    case dashboardSetAssideDataAttendance.toString():
      draft.assideAttendanceGraph = payload;
      break;
    case dashboardSetAssideDataActiveMemberships.toString():
      draft.assideActiveMembershipsGraph = payload;
      break;
    case dashboardSetAssideDataMembershipsRevenue.toString():
      draft.assideMembershipRevenueGraph = payload;
      break;
    case dashboardGetFirstTimersGraph.REQUEST:
      draft.loadingFirstTimersGraphData = true;
      break;
    case dashboardGetAttendanceGraph.REQUEST:
      draft.loadingAttendanceGraphData = true;
      break;
    case dashboardGetMembershipRevenueGraph.REQUEST:
      draft.loadingMembershipRevenueGraphData = true;
      break;
    case dashboardGetActiveMembershipsGraph.REQUEST:
      draft.loadingActiveMembershipsGraphData = true;
      break;
    case dashboardGetFirstTimersGraph.SUCCESS:
      draft.firstTimersGraphData = payload;
      draft.assideFirstTimersGraph = payload.graphDatasets[0].growthFeedback;
      break;
    case dashboardGetAttendanceGraph.SUCCESS:
      draft.attendanceGraphData = payload;
      draft.assideAttendanceGraph = payload.graphDatasets[0].growthFeedback;
      break;
    case dashboardGetMembershipRevenueGraph.SUCCESS:
      draft.membershipRevenueGraphData = payload;
      draft.assideMembershipRevenueGraph =
        payload.graphDatasets[0].growthFeedback;
      break;
    case dashboardGetActiveMembershipsGraph.SUCCESS:
      draft.activeMembershipsGraphData = payload;
      draft.assideActiveMembershipsGraph =
        payload.graphDatasets[0].growthFeedback;
      break;
    case dashboardGetgymsPerformance.SUCCESS:
      draft.gymsPerformance = payload;
      break;
    case dashboardGetFirstTimersRanking.SUCCESS:
      draft.firstTimersRanking = payload;
      break;
    case dashboardGetClassAttendanceRanking.SUCCESS:
      draft.classAttendanceRanking = payload;
      break;
    case dashboardGetActiveMembershipsRanking.SUCCESS:
      draft.activeMembershipsRanking = payload;
      break;
    case dashboardGetMembershipsRevenueRanking.SUCCESS:
      draft.membershipsRevenueRanking = payload;
      break;
    case dashboardGetFirstTimersGraph.FULFILL:
      draft.loadingFirstTimersGraphData = false;
      break;
    case dashboardGetAttendanceGraph.FULFILL:
      draft.loadingAttendanceGraphData = false;
      break;
    case dashboardGetMembershipRevenueGraph.FULFILL:
      draft.loadingMembershipRevenueGraphData = false;
      break;
    case dashboardGetActiveMembershipsGraph.FULFILL:
      draft.loadingActiveMembershipsGraphData = false;
      break;
  }
}, initialState);

export default reducer;
