import { Redirect, Route } from 'react-router-dom';

/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const PublicRoute = ({ render, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(otherProps) =>
      isLoggedIn === false ? (
        render(otherProps)
      ) : (
        <Redirect
          to={{
            pathname: '/app',
            state: { from: otherProps.location },
          }}
        />
      )
    }
  />
);

export default PublicRoute;
