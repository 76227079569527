import { createSelector } from 'reselect';

export const getAuth = (state) => state.auth;

export const isAuthLoading = createSelector(getAuth, ({ loading }) => loading);
export const getAuthIsLoggedIn = createSelector(
  getAuth,
  (auth) => auth.isLoggedIn
);

export const isAuthShowPassword = createSelector(
  getAuth,
  ({ showPassword }) => showPassword
);
export const isAuthShowNewPassword = createSelector(
  getAuth,
  ({ showNewPassword }) => showNewPassword
);
export const isAuthShowConfirmPassword = createSelector(
  getAuth,
  ({ showConfirmPassword }) => showConfirmPassword
);

export const getAbility = createSelector(getAuth, ({ ability }) => ability);
