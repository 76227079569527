import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { formatOutgoingHoursHoliday } from 'utils/format/std-hours';
import { getGymItem } from 'modules/gyms/selectors';
import http from 'utils/http';
import parseData from 'utils/parse-data';
import { listHolidayHours, updateHolidayHours } from './actions';

export function* listHolidayHoursSaga() {
  try {
    yield put(listHolidayHours.request());
    const { data } = yield call(http, 'GetHolidaySched');
    if (data.success) {
      yield put(listHolidayHours.success(data.data));
    } else {
      yield put(listHolidayHours.failure(data.advisory));
    }
  } catch (error) {
    yield put(listHolidayHours.failure(error));
  } finally {
    yield put(listHolidayHours.fulfill());
  }
}

export function* updateHolidayHoursSaga({ payload: { values } }) {
  try {
    const currentGym = yield select(getGymItem);
    const newValues = formatOutgoingHoursHoliday(values);
    newValues.idS_Property = currentGym;
    newValues.idS_HolidaySched = values.idS_HolidaySched;
    const formData = parseData(newValues);
    yield put(updateHolidayHours.request());
    const { data } = yield call(
      http,
      'SetHolidayHours',
      'post',
      formData,
      false
    );
    if (data) {
      yield put(listHolidayHours.trigger());
      yield put(updateHolidayHours.success());
    } else {
      yield put(updateHolidayHours.failure(data.advisory));
    }
  } catch (error) {
    yield put(updateHolidayHours.failure(error));
  } finally {
    yield put(updateHolidayHours.fulfill());
  }
}

export default function* holidayHoursWatcher() {
  yield all([
    takeLatest(listHolidayHours.TRIGGER, listHolidayHoursSaga),
    takeLatest(updateHolidayHours.TRIGGER, updateHolidayHoursSaga),
  ]);
}
