import { createAction } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';
import {
  DASHBOARD_DATEPICKER_TOOGLE,
  DASHBOARD_GET_ACTIVE_MEMBERSHIPS_RANKING,
  DASHBOARD_GET_ACTIVE_MEMBERSHIP_GRAPH,
  DASHBOARD_GET_ATTENDANCE_GRAPH,
  DASHBOARD_GET_CLASS_ATTENDANCE_RANKING,
  DASHBOARD_GET_FIRST_TIMERS_GRAPH,
  DASHBOARD_GET_FIRST_TIMERS_RANKING,
  DASHBOARD_GET_GYMS_PERFORMANCE,
  DASHBOARD_GET_MEMBERSHIPS_REVENUE_RANKING,
  DASHBOARD_GET_MEMBERSHIP_REVENUE_GRAPH,
  DASHBOARD_SET_ACTIVE_TAB,
  DASHBOARD_SET_ASSIDE_DATA_ACTIVE_MEMBERSHIPS,
  DASHBOARD_SET_ASSIDE_DATA_ATTENDANCE,
  DASHBOARD_SET_ASSIDE_DATA_FIRST_TIMERS,
  DASHBOARD_SET_ASSIDE_DATA_MEMBERSHIP_REVENUE,
  DASHBOARD_SET_FILTERS,
} from './types';

export const dashboardSetAssideDataFirstTimers = createAction(
  DASHBOARD_SET_ASSIDE_DATA_FIRST_TIMERS
);
export const dashboardSetAssideDataAttendance = createAction(
  DASHBOARD_SET_ASSIDE_DATA_ATTENDANCE
);
export const dashboardSetAssideDataActiveMemberships = createAction(
  DASHBOARD_SET_ASSIDE_DATA_ACTIVE_MEMBERSHIPS
);
export const dashboardSetAssideDataMembershipsRevenue = createAction(
  DASHBOARD_SET_ASSIDE_DATA_MEMBERSHIP_REVENUE
);
export const dashboardSetActiveTab = createAction(DASHBOARD_SET_ACTIVE_TAB);
export const dashboardSetFilters = createAction(DASHBOARD_SET_FILTERS);
export const dashboardToogleDatePicker = createAction(
  DASHBOARD_DATEPICKER_TOOGLE
);
export const dashboardGetFirstTimersGraph = createRoutine(
  DASHBOARD_GET_FIRST_TIMERS_GRAPH
);
export const dashboardGetAttendanceGraph = createRoutine(
  DASHBOARD_GET_ATTENDANCE_GRAPH
);
export const dashboardGetMembershipRevenueGraph = createRoutine(
  DASHBOARD_GET_MEMBERSHIP_REVENUE_GRAPH
);
export const dashboardGetActiveMembershipsGraph = createRoutine(
  DASHBOARD_GET_ACTIVE_MEMBERSHIP_GRAPH
);
export const dashboardGetgymsPerformance = createRoutine(
  DASHBOARD_GET_GYMS_PERFORMANCE
);
export const dashboardGetFirstTimersRanking = createRoutine(
  DASHBOARD_GET_FIRST_TIMERS_RANKING
);
export const dashboardGetClassAttendanceRanking = createRoutine(
  DASHBOARD_GET_CLASS_ATTENDANCE_RANKING
);
export const dashboardGetActiveMembershipsRanking = createRoutine(
  DASHBOARD_GET_ACTIVE_MEMBERSHIPS_RANKING
);
export const dashboardGetMembershipsRevenueRanking = createRoutine(
  DASHBOARD_GET_MEMBERSHIPS_REVENUE_RANKING
);
