import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';

import { createAction } from 'redux-actions';
import {
  RUN_SETTINGS_BY_LOCATION,
  SETTINGS_BY_LOCATION_LIST,
  SET_LOCATION,
} from './types';

export const setLocation = createAction(SET_LOCATION);

export const settingsByLocationList = createRoutine(SETTINGS_BY_LOCATION_LIST);
export const runSettingsByLocation = createRoutine(RUN_SETTINGS_BY_LOCATION);

export const bindedRunSettingsByLocation = bindRoutineToReduxForm(
  runSettingsByLocation
);
