import produce from 'immer';
import {
  runSettingsByLocation,
  setLocation,
  settingsByLocationList,
} from './actions';

/* eslint-disable default-case */
/* eslint-disable no-param-reassign */

const initialState = {
  error: null,
  loading: false,
  items: [],
  item: null,
  location: null,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case settingsByLocationList.TRIGGER:
    case runSettingsByLocation.TRIGGER:
      draft.loading = true;
      draft.error = null;
      break;
    case settingsByLocationList.SUCCESS:
      draft.items = payload;
      break;
    case runSettingsByLocation.SUCCESS:
      draft.item = payload;
      break;
    case settingsByLocationList.FAILURE:
    case runSettingsByLocation.FAILURE:
      draft.error = payload;
      break;
    case settingsByLocationList.FULFILL:
    case runSettingsByLocation.FULFILL:
      draft.loading = false;
      break;

    case setLocation.toString():
      draft.location = payload;
      break;
  }
}, initialState);

export default reducer;
