import 'utils/axios-setup';
import 'react-toastify/dist/ReactToastify.css';

import { persistor, store } from 'store';

import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import React from 'react';
import Routes from 'routes';
import ScreenLoading from 'views/ui/screen-loading';
import SdkLoader from 'views/components/sdk-loader';
import { ToastContainer } from 'react-toastify';
import history from 'store/history';
import routesConfig from 'routes/config';

const App = () => (
  <Provider store={store}>
    <PersistGate loading={<ScreenLoading />} persistor={persistor}>
      <SdkLoader>
        <ConnectedRouter history={history}>
          <Routes store={store} routes={routesConfig} />
          <ToastContainer />
        </ConnectedRouter>
      </SdkLoader>
    </PersistGate>
  </Provider>
);

export default App;
