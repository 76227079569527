import {
  createRoutine,
  bindRoutineToReduxForm,
  promisifyRoutine,
} from 'redux-saga-routines';
import {
  LIST_DISCOUNTS,
  ADD_DISCOUNT,
  UPDATE_DISCOUNT,
  LIST_COUPONS,
  ADD_COUPON,
  UPDATE_COUPON,
  LIST_MEMBER_METHODS,
  LIST_MEMBER_TYPES,
  ADD_SKU,
  UPDATE_SKU,
  LIST_SKUS,
  LIST_SKU_CATEGORIES,
  LIST_MEMBER_TYPES_METHODS,
  LIST_PRICELISTS,
  UPDATE_PRICELIST,
  LIST_PACKAGES,
  ADD_PACKAGE,
  UPDATE_PACKAGE,
  LIST_PACKAGE_DETAIL,
  ADD_PACKAGE_DETAIL,
  UPDATE_PACKAGE_DETAIL,
} from './types';

export const listDiscounts = createRoutine(LIST_DISCOUNTS);
export const addDiscount = createRoutine(ADD_DISCOUNT);
export const updateDiscount = createRoutine(UPDATE_DISCOUNT);

export const listMemberTypes = createRoutine(LIST_MEMBER_TYPES);
export const listMemberMethods = createRoutine(LIST_MEMBER_METHODS);

export const listCoupons = createRoutine(LIST_COUPONS);
export const addCoupon = createRoutine(ADD_COUPON);
export const updateCoupon = createRoutine(UPDATE_COUPON);

export const listSkus = createRoutine(LIST_SKUS);
export const listSkusCategories = createRoutine(LIST_SKU_CATEGORIES);
export const addSku = createRoutine(ADD_SKU);
export const updateSku = createRoutine(UPDATE_SKU);

export const listPackages = createRoutine(LIST_PACKAGES);
export const addPackage = createRoutine(ADD_PACKAGE);
export const updatePackage = createRoutine(UPDATE_PACKAGE);

export const listPackageDetail = createRoutine(LIST_PACKAGE_DETAIL);
export const promisifyListDetail = promisifyRoutine(listPackageDetail);
export const addPackageDetail = createRoutine(ADD_PACKAGE_DETAIL);
export const updatePackageDetail = createRoutine(UPDATE_PACKAGE_DETAIL);

export const listPricelists = createRoutine(LIST_PRICELISTS);
export const updatePricelist = createRoutine(UPDATE_PRICELIST);

export const listMemberTypesMethods = createRoutine(LIST_MEMBER_TYPES_METHODS);

/* REDUX FORM ACTIONS */
export const bindedUpdateDiscount = bindRoutineToReduxForm(updateDiscount);
export const bindedAddDiscount = bindRoutineToReduxForm(addDiscount);

export const bindedUpdateCoupon = bindRoutineToReduxForm(updateCoupon);
export const bindedAddCoupon = bindRoutineToReduxForm(addCoupon);

export const bindedUpdateSku = bindRoutineToReduxForm(updateSku);
export const bindedAddSku = bindRoutineToReduxForm(addSku);

export const bindedUpdatePackage = bindRoutineToReduxForm(updatePackage);
export const bindedAddPackage = bindRoutineToReduxForm(addPackage);

export const bindedUpdatePricelist = bindRoutineToReduxForm(updatePricelist);
