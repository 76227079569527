/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  listReports,
  listReportLog,
  listScheduled,
  runReport,
  createSchedule,
  updateSchedule,
} from './actions';
import { CLOSE_MODAL, SET_CURRENT } from './types';

const initialState = {
  error: null,
  loading: false,
  items: [],
  logs: [],
  schedules: [],
  openModal: false,
  currentSchedule: null,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case listReports.TRIGGER:
    case listReportLog.TRIGGER:
    case listScheduled.TRIGGER:
    case createSchedule.TRIGGER:
    case updateSchedule.TRIGGER:
      draft.loading = true;
      draft.error = null;
      break;
    case runReport.TRIGGER:
      draft.loading = true;
      draft.error = null;
      draft.openModal = true;
      break;
    case listReports.SUCCESS:
      draft.items = payload;
      break;
    case listReportLog.SUCCESS:
      draft.logs = payload;
      break;
    case listScheduled.SUCCESS:
    case createSchedule.SUCCESS:
    case updateSchedule.SUCCESS:
      draft.schedules = payload;
      break;
    case listReports.FAILURE:
    case listReportLog.FAILURE:
    case listScheduled.FAILURE:
    case createSchedule.FAILURE:
    case updateSchedule.FAILURE:
    case runReport.FAILURE:
      draft.error = payload;
      break;
    case listReports.FULFILL:
    case listReportLog.FULFILL:
    case listScheduled.FULFILL:
    case createSchedule.FULFILL:
    case updateSchedule.FULFILL:
    case runReport.FULFILL:
      draft.loading = false;
      break;
    case CLOSE_MODAL:
      draft.openModal = false;
      break;
    case SET_CURRENT:
      draft.currentSchedule = payload;
      break;
  }
}, initialState);

export default reducer;
