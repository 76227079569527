/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
export const formatIncomingHours = (initial) => {
  if (initial == null) return null;
  const daysnumber = [1, 2, 3, 4, 5, 6, 7];
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const newHours = {};
  daysnumber.forEach((element) => {
    newHours[days[element - 1]] = [];
    for (let i = 0; i < initial[`day${element}cnt`]; i++) {
      newHours[days[element - 1]].push({});
      newHours[days[element - 1]][i] = {
        [`_${element}session${i + 1}`]: {
          [`_${element}session${i + 1}Last`]:
            initial[`_${element}session${i + 1}Last`],
          [`_${element}session${i + 1}Start`]:
            initial[`_${element}session${i + 1}Start`],
        },
      };
    }
  });
  return newHours;
};

export const formatOutgoingHours = (initial) => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const indexes = ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven'];

  const newHours = {};
  newHours.StdScheduleGym = initial.StdScheduleGym;
  days.forEach((element, index) => {
    newHours[`day${index + 1}cnt`] = initial[element].length;
    for (let i = 0; i < initial[element].length; i++) {
      newHours[`${indexes[index]}Session${i + 1}Last`] =
        initial[element][i][`_${index + 1}session${i + 1}`][
          `_${index + 1}session${i + 1}Last`
        ];
      newHours[`${indexes[index]}Session${i + 1}Start`] =
        initial[element][i][`_${index + 1}session${i + 1}`][
          `_${index + 1}session${i + 1}Start`
        ];
    }
    for (let i = 2; i >= initial[element].length; i--) {
      newHours[`${indexes[index]}Session${i + 1}Last`] = '';
      newHours[`${indexes[index]}Session${i + 1}Start`] = '';
    }
  });

  return newHours;
};

/** HOLIDAY FORMATTING */

export const formatIncomingHoursHoliday = (initial) => {
  if (initial == null) return null;
  const { idS_Property, idS_HolidaySched, HolidayDate, PropOpen, description } =
    initial;
  const newHours = {
    idS_Property,
    idS_HolidaySched,
    HolidayDate,
    PropOpen,
    description,
  };

  newHours.days = [];
  for (let i = 0; i < 3; i++) {
    newHours.days[i] = {
      [`Session${i + 1}`]: {
        [`Session${i + 1}FirstStart`]: initial[`Session${i + 1}FirstStart`],
        [`Session${i + 1}LastStart`]: initial[`Session${i + 1}LastStart`],
      },
    };
  }
  return newHours;
};

export const formatOutgoingHoursHoliday = (initial) => {
  const days = ['days'];

  const { idS_Property, idS_HolidaySched, HolidayDate, PropOpen, description } =
    initial;
  const newHours = {
    idS_Property,
    idS_HolidaySched,
    HolidayDate,
    PropOpen,
    description,
  };

  days.forEach((element) => {
    for (let i = 0; i < initial[element].length; i++) {
      newHours[`Session${i + 1}FirstStart`] =
        initial[element][i][`Session${i + 1}`][`Session${i + 1}FirstStart`];
      newHours[`Session${i + 1}LastStart`] =
        initial[element][i][`Session${i + 1}`][`Session${i + 1}LastStart`];
    }
    for (let i = 2; i >= initial[element].length; i--) {
      newHours[`Session${i + 1}FirstStart`] = '';
      newHours[`Session${i + 1}LastStart`] = '';
    }
  });

  return newHours;
};
