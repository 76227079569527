import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import SDK from 'utils/sdk';
import {
  dashboardGetActiveMembershipsGraph,
  dashboardGetActiveMembershipsRanking,
  dashboardGetAttendanceGraph,
  dashboardGetClassAttendanceRanking,
  dashboardGetFirstTimersGraph,
  dashboardGetFirstTimersRanking,
  dashboardGetMembershipRevenueGraph,
  dashboardGetMembershipsRevenueRanking,
  dashboardGetgymsPerformance,
} from './actions';

import { getDashboardFilters } from './selectors';

function* GetFirstTimersGraphSaga() {
  try {
    yield put(dashboardGetFirstTimersGraph.request());
    const { dateRange, locations } = yield select(getDashboardFilters);
    const client = yield call([SDK, 'getClient']);
    const { data } = yield call(
      [client, 'getFirstTimerGraph'],
      {},
      {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        locationIds: locations.length
          ? locations.map(({ value }) => value)
          : null,
      }
    );
    yield put(dashboardGetFirstTimersGraph.success(data));
  } catch (error) {
    yield put(dashboardGetFirstTimersGraph.failure(error));
  } finally {
    yield put(dashboardGetFirstTimersGraph.fulfill());
  }
}
function* GetAttendanceGraphSaga() {
  try {
    yield put(dashboardGetAttendanceGraph.request());
    const { dateRange, locations } = yield select(getDashboardFilters);
    const client = yield call([SDK, 'getClient']);
    const { data } = yield call(
      [client, 'getAttendanceGraph'],
      {},
      {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        locationIds: locations.length
          ? locations.map(({ value }) => value)
          : null,
      }
    );
    yield put(dashboardGetAttendanceGraph.success(data));
  } catch (error) {
    yield put(dashboardGetAttendanceGraph.failure(error));
  } finally {
    yield put(dashboardGetAttendanceGraph.fulfill());
  }
}
function* GetMembershipRevenueGraphSaga() {
  try {
    yield put(dashboardGetMembershipRevenueGraph.request());
    const { dateRange, locations } = yield select(getDashboardFilters);
    const client = yield call([SDK, 'getClient']);
    const { data } = yield call(
      [client, 'getMembershipRevenueGraph'],
      {},
      {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        locationIds: locations.length
          ? locations.map(({ value }) => value)
          : null,
      }
    );
    yield put(dashboardGetMembershipRevenueGraph.success(data));
  } catch (error) {
    yield put(dashboardGetMembershipRevenueGraph.failure(error));
  } finally {
    yield put(dashboardGetMembershipRevenueGraph.fulfill());
  }
}

function* GetActiveMembershipGraphSaga() {
  try {
    yield put(dashboardGetActiveMembershipsGraph.request());
    const { dateRange, locations } = yield select(getDashboardFilters);
    const client = yield call([SDK, 'getClient']);
    const { data } = yield call(
      [client, 'getActiveMembershipGraph'],
      {},
      {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        locationIds: locations.length
          ? locations.map(({ value }) => value)
          : null,
      }
    );
    yield put(dashboardGetActiveMembershipsGraph.success(data));
  } catch (error) {
    yield put(dashboardGetActiveMembershipsGraph.failure(error));
  } finally {
    yield put(dashboardGetActiveMembershipsGraph.fulfill());
  }
}

function* GetGymsPerformanceSaga() {
  try {
    yield put(dashboardGetgymsPerformance.request());
    const client = yield call([SDK, 'getClient']);
    const { data } = yield call([client, 'getStudiosPerformance']);
    yield put(dashboardGetgymsPerformance.success(data));
  } catch (error) {
    yield put(dashboardGetgymsPerformance.failure(error));
  } finally {
    yield put(dashboardGetgymsPerformance.fulfill());
  }
}

function* GetFirstTimersRankingSaga() {
  try {
    yield put(dashboardGetFirstTimersRanking.request());
    const client = yield call([SDK, 'getClient']);
    const { data } = yield call([client, 'getFirstTimersRanking']);
    yield put(dashboardGetFirstTimersRanking.success(data));
  } catch (error) {
    yield put(dashboardGetFirstTimersRanking.failure(error));
  } finally {
    yield put(dashboardGetFirstTimersRanking.fulfill());
  }
}

function* GetClassAttendanceRankingSaga() {
  try {
    yield put(dashboardGetClassAttendanceRanking.request());
    const client = yield call([SDK, 'getClient']);
    const { data } = yield call([client, 'getClassAttendanceRanking']);
    yield put(dashboardGetClassAttendanceRanking.success(data));
  } catch (error) {
    yield put(dashboardGetClassAttendanceRanking.failure(error));
  } finally {
    yield put(dashboardGetClassAttendanceRanking.fulfill());
  }
}
function* GetActiveMembershipsRankingSaga() {
  try {
    yield put(dashboardGetActiveMembershipsRanking.request());
    const client = yield call([SDK, 'getClient']);
    const { data } = yield call([client, 'getActiveMembershipRanking']);
    yield put(dashboardGetActiveMembershipsRanking.success(data));
  } catch (error) {
    yield put(dashboardGetActiveMembershipsRanking.failure(error));
  } finally {
    yield put(dashboardGetActiveMembershipsRanking.fulfill());
  }
}
function* GetMembershipsRevenueRankingSaga() {
  try {
    yield put(dashboardGetMembershipsRevenueRanking.request());
    const client = yield call([SDK, 'getClient']);
    const { data } = yield call([client, 'getMembershipRevenueRanking']);
    yield put(dashboardGetMembershipsRevenueRanking.success(data));
  } catch (error) {
    yield put(dashboardGetMembershipsRevenueRanking.failure(error));
  } finally {
    yield put(dashboardGetMembershipsRevenueRanking.fulfill());
  }
}

export default function* dashboardWatcher() {
  yield all([
    takeLatest(dashboardGetFirstTimersGraph.TRIGGER, GetFirstTimersGraphSaga),
    takeLatest(dashboardGetAttendanceGraph.TRIGGER, GetAttendanceGraphSaga),
    takeLatest(
      dashboardGetMembershipRevenueGraph.TRIGGER,
      GetMembershipRevenueGraphSaga
    ),
    takeLatest(
      dashboardGetActiveMembershipsGraph.TRIGGER,
      GetActiveMembershipGraphSaga
    ),
    takeLatest(dashboardGetgymsPerformance.TRIGGER, GetGymsPerformanceSaga),
    takeLatest(
      dashboardGetFirstTimersRanking.TRIGGER,
      GetFirstTimersRankingSaga
    ),
    takeLatest(
      dashboardGetClassAttendanceRanking.TRIGGER,
      GetClassAttendanceRankingSaga
    ),
    takeLatest(
      dashboardGetActiveMembershipsRanking.TRIGGER,
      GetActiveMembershipsRankingSaga
    ),
    takeLatest(
      dashboardGetMembershipsRevenueRanking.TRIGGER,
      GetMembershipsRevenueRankingSaga
    ),
  ]);
}
