/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  listDiscounts,
  addDiscount,
  updateDiscount,
  listCoupons,
  addCoupon,
  updateCoupon,
  listMemberMethods,
  listMemberTypes,
  listSkus,
  listMemberTypesMethods,
  listSkusCategories,
  listPricelists,
  addPackage,
  listPackages,
  updatePackage,
  addPackageDetail,
  listPackageDetail,
  updatePackageDetail,
} from './actions';

const initialState = {
  error: null,
  loading: false,
  discounts: [],
  coupons: [],
  packages: [],
  packageItem: null,
  packageDetail: null,
  pricelists: [],
  categories: [],
  types: [],
  methods: [],
  typesMethods: [],
  skus: [],
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case listDiscounts.TRIGGER:
    case listMemberTypes.TRIGGER:
    case listMemberMethods.TRIGGER:
    case listCoupons.TRIGGER:
    case listSkus.TRIGGER:
    case listMemberTypesMethods.TRIGGER:
    case listSkusCategories.TRIGGER:
    case listPricelists.TRIGGER:
    case listPackages.TRIGGER:
    case listPackageDetail.TRIGGER:
    case addDiscount.TRIGGER:
    case addCoupon.TRIGGER:
    case addPackage.TRIGGER:
    case addPackageDetail.TRIGGER:
    case updateDiscount.TRIGGER:
    case updateCoupon.TRIGGER:
    case updatePackage.TRIGGER:
    case updatePackageDetail.TRIGGER:
      draft.loading = true;
      draft.error = null;
      break;
    case listDiscounts.SUCCESS:
      draft.discounts = payload;
      break;
    case listMemberTypes.SUCCESS:
      draft.types = payload;
      break;
    case listMemberMethods.SUCCESS:
      draft.methods = payload;
      break;
    case listCoupons.SUCCESS:
      draft.coupons = payload;
      break;
    case listSkus.SUCCESS:
      draft.skus = payload;
      break;
    case listMemberTypesMethods.SUCCESS:
      draft.typesMethods = payload;
      break;
    case listSkusCategories.SUCCESS:
      draft.categories = payload;
      break;
    case listPricelists.SUCCESS:
      draft.pricelists = payload;
      break;
    case listPackages.SUCCESS:
      draft.packages = payload;
      break;
    case listPackageDetail.SUCCESS:
      draft.packageDetail = payload;
      break;
    case listDiscounts.FAILURE:
    case listMemberTypes.FAILURE:
    case listMemberMethods.FAILURE:
    case listCoupons.FAILURE:
    case listSkus.FAILURE:
    case listMemberTypesMethods.FAILURE:
    case listSkusCategories.FAILURE:
    case listPricelists.FAILURE:
    case listPackages.FAILURE:
    case listPackageDetail.FAILURE:
    case addDiscount.FAILURE:
    case addCoupon.FAILURE:
    case addPackage.FAILURE:
    case addPackageDetail.FAILURE:
    case updateDiscount.FAILURE:
    case updateCoupon.FAILURE:
    case updatePackage.FAILURE:
    case updatePackageDetail.FAILURE:
      draft.error = payload;
      break;
    case listDiscounts.FULFILL:
    case listMemberTypes.FULFILL:
    case listMemberMethods.FULFILL:
    case listCoupons.FULFILL:
    case listSkus.FULFILL:
    case listMemberTypesMethods.FULFILL:
    case listSkusCategories.FULFILL:
    case listPricelists.FULFILL:
    case listPackages.FULFILL:
    case listPackageDetail.FULFILL:
    case addDiscount.FULFILL:
    case addCoupon.FULFILL:
    case addPackage.FULFILL:
    case addPackageDetail.FULFILL:
    case updateDiscount.FULFILL:
    case updateCoupon.FULFILL:
    case updatePackage.FULFILL:
    case updatePackageDetail.FULFILL:
      draft.loading = false;
      break;
  }
}, initialState);

export default reducer;
