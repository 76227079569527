import 'assets/styles/style.scss';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-datepicker/dist/react-datepicker.css';

import {
  faBars,
  faCalendar,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faFileDownload,
  faSearch,
  faTimes,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { hydrate, render } from 'react-dom';

import Loadable from 'react-loadable';
import React from 'react';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import App from './app';

library.add(
  faUser,
  faBars,
  faTimes,
  faCheck,
  faCalendar,
  faChevronLeft,
  faChevronRight,
  faSearch,
  faFileDownload,
  // @ts-ignore
  faTrashAlt,
  fab
);

// @ts-ignore
const renderMethod = module.hot ? render : hydrate;

Loadable.preloadReady().then(() => {
  renderMethod(<App />, document.getElementById('root'));
});
