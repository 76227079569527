import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { SubmissionError } from 'redux-form';
import { getGymItem } from 'modules/gyms/selectors';
import http from 'utils/http';
import parseData from 'utils/parse-data';
import {
  addCoupon,
  addDiscount,
  addPackage,
  addPackageDetail,
  addSku,
  listCoupons,
  listDiscounts,
  listMemberMethods,
  listMemberTypes,
  listMemberTypesMethods,
  listPackageDetail,
  listPackages,
  listPricelists,
  listSkus,
  listSkusCategories,
  updateCoupon,
  updateDiscount,
  updatePackage,
  updatePackageDetail,
  updatePricelist,
  updateSku,
} from './actions';

export function* listDiscountsSaga() {
  try {
    yield put(listDiscounts.request());
    const { data } = yield call(http, 'wwGetSweatDiscount');
    if (data.success) {
      yield put(listDiscounts.success(data.data));
    } else {
      yield put(listDiscounts.failure(data.advisory));
    }
  } catch (error) {
    yield put(listDiscounts.failure(error));
  } finally {
    yield put(listDiscounts.fulfill());
  }
}

export function* addDiscountSaga({ payload: { values } }) {
  try {
    yield put(addDiscount.request());
    const formData = parseData(values);
    const { data } = yield call(http, 'wwAddSweatDiscount', 'POST', formData);
    if (data.success) {
      yield put(listDiscounts.trigger());
      yield put(addDiscount.success(data));
    } else {
      yield put(
        addDiscount.failure(new SubmissionError({ _error: data.advisory }))
      );
    }
  } catch (error) {
    yield put(addDiscount.failure(error));
  } finally {
    yield put(addDiscount.fulfill());
  }
}

export function* updateDiscountSaga({ payload: { values } }) {
  try {
    yield put(updateDiscount.request());
    const formData = parseData(values);
    const { data } = yield call(http, 'wwUpdSweatDiscount', 'POST', formData);
    if (data.success) {
      yield put(listDiscounts.trigger());
      yield put(updateDiscount.success());
    } else {
      yield put(
        updateDiscount.failure(new SubmissionError({ _error: data.advisory }))
      );
    }
  } catch (error) {
    yield put(updateDiscount.failure(error));
  } finally {
    yield put(updateDiscount.fulfill());
  }
}

export function* listMemberTypesSaga() {
  try {
    yield put(listMemberTypes.request());
    const { data } = yield call(http, 'wwGetSweatMemberType');
    if (data.success) {
      yield put(listMemberTypes.success(data.data));
    } else {
      yield put(listMemberTypes.failure(data.advisory));
    }
  } catch (error) {
    yield put(listMemberTypes.failure(error));
  } finally {
    yield put(listMemberTypes.fulfill());
  }
}

export function* listMemberMethodsSaga() {
  try {
    yield put(listMemberMethods.request());
    const { data } = yield call(http, 'wwGetSweatMemberMethod');
    if (data.success) {
      yield put(listMemberMethods.success(data.data));
    } else {
      yield put(listMemberMethods.failure(data.advisory));
    }
  } catch (error) {
    yield put(listMemberMethods.failure(error));
  } finally {
    yield put(listMemberMethods.fulfill());
  }
}

export function* listCouponsSaga() {
  try {
    yield put(listCoupons.request());
    const { data } = yield call(http, 'wwGetSweatCoupon');
    if (data.success) {
      yield put(listCoupons.success(data.data));
    } else {
      yield put(listCoupons.failure(data.advisory));
    }
  } catch (error) {
    yield put(listCoupons.failure(error));
  } finally {
    yield put(listCoupons.fulfill());
  }
}

export function* updateCouponsSaga({ payload: { values } }) {
  try {
    yield put(updateCoupon.request());
    const formData = parseData(values);
    const { data } = yield call(http, 'wwUpdSweatCoupon', 'POST', formData);
    if (data.success) {
      yield put(listCoupons.trigger());
      yield put(updateCoupon.success());
    } else {
      yield put(
        updateCoupon.failure(new SubmissionError({ _error: data.advisory }))
      );
    }
  } catch (error) {
    yield put(updateCoupon.failure(error));
  } finally {
    yield put(updateCoupon.fulfill());
  }
}

export function* addCouponSaga({ payload: { values } }) {
  try {
    yield put(addCoupon.request());
    const formData = parseData(values);
    const { data } = yield call(http, 'wwAddSweatCoupon', 'POST', formData);
    if (data.success) {
      yield put(listCoupons.trigger());
      yield put(addCoupon.success());
    } else {
      yield put(
        addCoupon.failure(new SubmissionError({ _error: data.advisory }))
      );
    }
  } catch (error) {
    yield put(addCoupon.failure(error));
  } finally {
    yield put(addCoupon.fulfill());
  }
}

export function* listSkusCategoriesSaga() {
  try {
    yield put(listSkusCategories.request());
    const { data } = yield call(http, 'wwGetSweatSkuCat');
    if (data.success) {
      yield put(listSkusCategories.success(data.data));
    } else {
      yield put(listSkusCategories.failure(data.advisory));
    }
  } catch (error) {
    yield put(listSkusCategories.failure(error));
  } finally {
    yield put(listSkusCategories.fulfill());
  }
}

export function* listSkusSaga() {
  try {
    yield put(listSkus.request());
    const { data } = yield call(http, 'wwGetSweatSku');
    if (data.success) {
      yield put(listSkus.success(data.data));
    } else {
      yield put(listSkus.failure(data.advisory));
    }
  } catch (error) {
    yield put(listSkus.failure(error));
  } finally {
    yield put(listSkus.fulfill());
  }
}

export function* addSkuSaga({ payload: { values } }) {
  try {
    yield put(addSku.request());
    const formData = parseData(values);
    const { data } = yield call(http, 'wwAddSweatSku', 'POST', formData);
    if (data.success) {
      yield put(listSkus.trigger());
      yield put(addSku.success());
    } else {
      yield put(addSku.failure(new SubmissionError({ _error: data.advisory })));
    }
  } catch (error) {
    yield put(addSku.failure(error));
  } finally {
    yield put(addSku.fulfill());
  }
}

export function* updateSkuSaga({ payload: { values } }) {
  try {
    yield put(updateSku.request());
    const formData = parseData(values);
    const { data } = yield call(http, 'wwUpdSweatSku', 'POST', formData);
    if (data.success) {
      yield put(listSkus.trigger());
      yield put(updateSku.success());
    } else {
      yield put(
        updateSku.failure(new SubmissionError({ _error: data.advisory }))
      );
    }
  } catch (error) {
    yield put(updateSku.failure(error));
  } finally {
    yield put(updateSku.fulfill());
  }
}

export function* listPackagesSaga() {
  try {
    yield put(listPackages.request());
    const { data } = yield call(http, 'wwGetSweatpackage');
    if (data.success) {
      yield put(listPackages.success(data.data));
    } else {
      yield put(listPackages.failure(data.advisory));
    }
  } catch (error) {
    yield put(listPackages.failure(error));
  } finally {
    yield put(listPackages.fulfill());
  }
}

export function* addPackageSaga({ payload: { values } }) {
  try {
    yield put(addPackage.request());
    const {
      packagename,
      packagedescription,
      sweatdiscounts,
      defaultPrice,
      items,
    } = values;
    const formDataPackage = parseData({
      packagename,
      packagedescription,
      sweatdiscounts,
      defaultPrice,
    });
    const { data: dataPackage } = yield call(
      http,
      'wwAddSweatPackage',
      'POST',
      formDataPackage
    );
    if (dataPackage.success) {
      const formDataDetail = parseData({
        sweatpackage: dataPackage.data[0].idsweatweatPackage,
        items: JSON.stringify(items),
      });
      const { data } = yield call(
        http,
        'wwAddSweatpackageDetailJ',
        'POST',
        formDataDetail
      );

      if (data.success) {
        yield put(listPackages.trigger());
        yield put(addPackage.success());
      } else {
        yield put(
          addPackage.failure(new SubmissionError({ _error: data.advisory }))
        );
      }
    } else {
      yield put(
        addPackage.failure(
          new SubmissionError({ _error: dataPackage.advisory })
        )
      );
    }
  } catch (error) {
    yield put(addPackage.failure(error));
  } finally {
    yield put(addPackage.fulfill());
  }
}

export function* updatePackageSaga({ payload: { values } }) {
  try {
    yield put(updatePackage.request());
    const {
      idsweatpackage,
      packagename,
      packagedescription,
      sweatdiscounts,
      defaultPrice,
      items,
    } = values;

    const formDataPackage = parseData({
      idsweatpackage,
      packagename,
      packagedescription,
      sweatdiscounts,
      defaultPrice,
    });
    const { data: dataPackage } = yield call(
      http,
      'wwUpdSweatPackage',
      'POST',
      formDataPackage
    );
    if (dataPackage.success) {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of items) {
        if (item.idsweatPackageDetail) {
          yield put(
            updatePackageDetail({
              sweatpackage: idsweatpackage,
              ...item,
            })
          );
        } else {
          yield put(
            addPackageDetail({
              idsweatpackage,
              ...item,
            })
          );
        }
      }
      yield put(listPackages.trigger());
      yield put(updatePackage.success());
    } else {
      yield put(
        updatePackage.failure(
          new SubmissionError({ _error: dataPackage.advisory })
        )
      );
    }
  } catch (error) {
    yield put(updatePackage.failure(error));
  } finally {
    yield put(updatePackage.fulfill());
  }
}

export function* listPackageDetailSaga({ payload }) {
  try {
    yield put(listPackageDetail.request());
    const formData = parseData({
      idsweatpackage: payload,
    });
    const { data } = yield call(
      http,
      'wwGetSweatpackageDetail',
      'POST',
      formData
    );
    if (data.success) {
      yield put(listPackageDetail.success(data.data));
    } else {
      yield put(listPackageDetail.failure(data.advisory));
    }
  } catch (error) {
    yield put(listPackageDetail.failure(error));
  } finally {
    yield put(listPackageDetail.fulfill());
  }
}

export function* addOnePackageDetailSaga({ payload }) {
  try {
    yield put(updatePackageDetail.request());
    const formData = parseData(payload);
    const { data } = yield call(
      http,
      'wwAddSweatpackageDetail',
      'POST',
      formData
    );
    if (data.success) {
      yield put(updatePackageDetail.success());
    } else {
      yield put(updatePackageDetail.failure(data.advisory));
    }
  } catch (error) {
    yield put(updatePackageDetail.failure(error));
  } finally {
    yield put(updatePackageDetail.fulfill());
  }
}

export function* updateOnePackageDetailSaga({ payload }) {
  try {
    yield put(updatePackageDetail.request());
    const formData = parseData(payload);
    const { data } = yield call(
      http,
      'wwUpdSweatpackageDetail',
      'POST',
      formData
    );
    if (data.success) {
      yield put(updatePackageDetail.success());
    } else {
      yield put(updatePackageDetail.failure(data.advisory));
    }
  } catch (error) {
    yield put(updatePackageDetail.failure(error));
  } finally {
    yield put(updatePackageDetail.fulfill());
  }
}

export function* listPricelistSaga() {
  try {
    yield put(listPricelists.request());
    const currentGym = yield select(getGymItem);
    const formData = parseData({
      ids_property: currentGym,
    });
    const { data } = yield call(http, 'wwGetSweatPriceList', 'post', formData);
    if (data.success) {
      yield put(listPricelists.success(data.data));
    } else {
      yield put(listPricelists.failure(data.advisory));
    }
  } catch (error) {
    yield put(listPricelists.failure(error));
  } finally {
    yield put(listPricelists.fulfill());
  }
}

export function* updatePricelistSaga({ payload: { values } }) {
  try {
    yield put(updatePricelist.request());
    const currentGym = yield select(getGymItem);
    const formValues = {
      idsweatPricelist: values.idSweatPriceList,
      active: values.PLActive,
      memberActive: values.MemberActive,
      conciergeActive: values.conciergeActive,
      taxable: values.taxable,
      price: values.price,
      rankMP: values.rankMP,
      sweatsku: values.idsweatsku,
    };
    const formData = parseData({ ...formValues, s_property: currentGym });
    const { data } = yield call(http, 'wwUpdSweatPriceList', 'POST', formData);
    if (data.success) {
      yield put(listPricelists.trigger());
      yield put(updatePricelist.success());
    } else {
      yield put(
        updatePricelist.failure(new SubmissionError({ _error: data.advisory }))
      );
    }
  } catch (error) {
    yield put(updatePricelist.failure(error));
  } finally {
    yield put(updatePricelist.fulfill());
  }
}

export function* listMemberTypesMethodsSaga() {
  try {
    yield put(listMemberTypesMethods.request());
    const { data } = yield call(http, 'wwGetSweatMemberTypeMethod');
    if (data.success) {
      yield put(listMemberTypesMethods.success(data.data));
    } else {
      yield put(listMemberTypesMethods.failure(data.advisory));
    }
  } catch (error) {
    yield put(listMemberTypesMethods.failure(error));
  } finally {
    yield put(listMemberTypesMethods.fulfill());
  }
}

export default function* skusWatcher() {
  yield all([takeLatest(listDiscounts.TRIGGER, listDiscountsSaga)]);
  yield all([takeLatest(addDiscount.TRIGGER, addDiscountSaga)]);
  yield all([takeLatest(updateDiscount.TRIGGER, updateDiscountSaga)]);
  yield all([takeLatest(listMemberTypes.TRIGGER, listMemberTypesSaga)]);
  yield all([takeLatest(listMemberMethods.TRIGGER, listMemberMethodsSaga)]);
  yield all([takeLatest(listCoupons.TRIGGER, listCouponsSaga)]);
  yield all([takeLatest(addCoupon.TRIGGER, addCouponSaga)]);
  yield all([takeLatest(updateCoupon.TRIGGER, updateCouponsSaga)]);
  yield all([takeLatest(listSkusCategories.TRIGGER, listSkusCategoriesSaga)]);
  yield all([takeLatest(listSkus.TRIGGER, listSkusSaga)]);
  yield all([takeLatest(addSku.TRIGGER, addSkuSaga)]);
  yield all([takeLatest(updateSku.TRIGGER, updateSkuSaga)]);
  yield all([takeLatest(listPackages.TRIGGER, listPackagesSaga)]);
  yield all([takeLatest(listPackageDetail.TRIGGER, listPackageDetailSaga)]);
  yield all([takeEvery(addPackageDetail.TRIGGER, addOnePackageDetailSaga)]);
  yield all([
    takeEvery(updatePackageDetail.TRIGGER, updateOnePackageDetailSaga),
  ]);
  yield all([takeLatest(addPackage.TRIGGER, addPackageSaga)]);
  yield all([takeLatest(updatePackage.TRIGGER, updatePackageSaga)]);
  yield all([takeLatest(listPricelists.TRIGGER, listPricelistSaga)]);
  yield all([takeLatest(updatePricelist.TRIGGER, updatePricelistSaga)]);
  yield all([
    takeLatest(listMemberTypesMethods.TRIGGER, listMemberTypesMethodsSaga),
  ]);
}
