import produce from 'immer';
import {
  getUser,
  listUsers,
  resetSelectedUser,
  setSelectedUser,
} from './actions';

/* eslint-disable default-case */
/* eslint-disable no-param-reassign */

const initialState = {
  error: null,
  users: [],
  user: null,
  groups: [],
  loading: false,
  username: null,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case resetSelectedUser.toString():
      draft.user = null;
      break;
    case setSelectedUser.toString():
      draft.user = payload;
      break;
    case listUsers.TRIGGER:
    case getUser.TRIGGER:
      draft.loading = true;
      draft.error = null;
      break;
    case listUsers.SUCCESS:
      draft.users = payload;
      break;
    case getUser.SUCCESS:
      draft.user = payload;
      break;
    case listUsers.FAILURE:
    case getUser.FAILURE:
      draft.error = payload;
      break;
    case listUsers.FULFILL:
    case getUser.FULFILL:
      draft.loading = false;
      break;
  }
}, initialState);

export default reducer;
