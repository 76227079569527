import { all, call, put, select, takeLatest } from 'redux-saga/effects';

// import { SubmissionError } from 'redux-form';
import camelcaseKeys from 'camelcase-keys';
// import parseData, { clearResponse } from 'utils/parse-data';
import { getMeItem } from 'modules/me/selectors';
import http from 'utils/http';
import { retrieveMe } from './actions';

export function* retrieveMeSaga() {
  try {
    yield put(retrieveMe.request());
    const me = yield select(getMeItem);
    const { data } = yield call(http, 'GetS4UserData');
    if (data.success) {
      const dataUser = data.data.find(
        (user) => user.idSweatClient === parseInt(me.idSweatClient, 10)
      );
      if (dataUser) {
        yield put(retrieveMe.success(camelcaseKeys(dataUser)));
      }
    } else {
      yield put(retrieveMe.failure(data.advisory));
    }
  } catch (error) {
    yield put(retrieveMe.failure(error));
  } finally {
    yield put(retrieveMe.fulfill());
  }
}

export default function* meWatcher() {
  yield all([takeLatest(retrieveMe.TRIGGER, retrieveMeSaga)]);
}
