/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { verifySession } from 'modules/auth/actions';

const PrivateRoute = ({ render, isLoggedIn, ...rest }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(verifySession());
  });

  return (
    <Route
      {...rest}
      render={(otherProps) =>
        isLoggedIn ? (
          render(otherProps)
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: otherProps.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
