/* eslint-disable no-prototype-builtins */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  closeChangePasswordModal,
  closeForgotPasswordModal,
} from 'modules/modal/actions';

import SDK from 'utils/sdk';
import { SubmissionError } from 'redux-form';
import { createAbilityForUser } from 'utils/casl/ability-utils';
import { getMeItem } from 'modules/me/selectors';
import { push } from 'connected-react-router';
import { retrieveMe } from 'modules/me/actions';
import { toast } from 'react-toastify';
import {
  changePassword,
  loginUser,
  logoutUser,
  resetPassword,
  resetPasswordRequest,
  showConfirmPassword,
  showNewPassword,
  showPassword,
  verifySession,
} from './actions';

export function* loginUserSaga({ payload: { values: requestBody } }) {
  try {
    yield put(loginUser.request());

    const client = yield call([SDK, 'getClient']);
    const response = yield call([client, 'userLogIn'], null, requestBody);

    if (response.status === 200) {
      const { data } = response;
      const ability = createAbilityForUser(data.permissions);
      yield put(retrieveMe.success(data));
      yield put(loginUser.success(ability));
    } else {
      const data = response.response?.data?.message?.toString();
      yield put(loginUser.failure(new SubmissionError({ _error: data })));
    }
  } catch (error) {
    yield put(
      loginUser.failure(
        new SubmissionError({
          _error:
            'Your account does not have permission to access this application',
        })
      )
    );
  } finally {
    yield put(loginUser.fulfill());
  }
}

export function* verifyUserSessionSaga() {
  try {
    yield put(verifySession.request());
    const client = yield call([SDK, 'getClient']);
    const response = yield call([client, 'verifyUserSession']);
    const { data } = response;
    const ability = createAbilityForUser(data.permissions);
    yield put(retrieveMe.success(data));
    yield put(verifySession.success(ability));
  } catch (error) {
    yield put(logoutUser());
    yield put(verifySession.failure());
  } finally {
    yield put(verifySession.fulfill());
  }
}

export function* logoutUserSaga() {
  try {
    const client = yield call([SDK, 'getClient']);
    yield call([client, 'userLogOut']);

    yield put(logoutUser.success());
  } catch (error) {
    const data = error?.response?.data?.message?.toString();
    yield put(logoutUser.failure(new SubmissionError({ _error: data })));
  } finally {
    yield put(logoutUser.fulfill());
  }
}

export function* changePasswordSaga({ payload: { values: requestBody } }) {
  try {
    yield put(changePassword.request());
    const { currentPassword, newPassword, confirmNewPassword } = requestBody;

    const me = yield select(getMeItem);
    const client = yield call([SDK, 'getClient']);

    const request = {
      currentPassword,
      newPassword,
      confirmNewPassword,
    };

    yield call([client, 'userUpdatePassword'], { id: me.id }, request);
    yield put(closeChangePasswordModal());
    yield put(showPassword(false));
    yield put(showNewPassword(false));
    yield put(showConfirmPassword(false));
    toast.success('Your password has been successfully changed');
    yield put(changePassword.success());
  } catch (error) {
    const data = error?.response?.data?.message?.toString();
    yield put(changePassword.failure(new SubmissionError({ _error: data })));
    toast.error(data);
  } finally {
    yield put(changePassword.fulfill());
  }
}

export function* resetPasswordRequestSaga({
  payload: { values: requestBody },
}) {
  try {
    yield put(resetPasswordRequest.request());

    const client = yield call([SDK, 'getClient']);
    yield call([client, 'userResetPasswordRequest'], null, requestBody);
    yield put(closeForgotPasswordModal());
    toast.success('Email sent successfully');
    yield put(resetPasswordRequest.success());
  } catch (error) {
    const data = error?.response?.data?.message?.toString();
    yield put(
      resetPasswordRequest.failure(new SubmissionError({ _error: data }))
    );
    toast.error(
      'Your account does not have permission to access this application'
    );
  } finally {
    yield put(resetPasswordRequest.fulfill());
  }
}

export function* resetPasswordSaga({ payload: { values } }) {
  try {
    yield put(resetPassword.request());

    const client = yield call([SDK, 'getClient']);

    yield call([client, 'userResetPassword'], null, values);
    yield put(push('/'));
    yield put(showNewPassword(false));
    yield put(showConfirmPassword(false));
    yield put(resetPassword.success());
    toast.success('Your password has been reset successfully');
  } catch (error) {
    const data = error?.response?.data?.message?.toString();
    yield put(resetPassword.failure(new SubmissionError({ _error: data })));
    toast.success(error);
  } finally {
    yield put(resetPassword.fulfill());
  }
}

export default function* authWatch() {
  yield all([
    takeLatest(loginUser.TRIGGER, loginUserSaga),
    takeLatest(logoutUser.TRIGGER, logoutUserSaga),
    takeLatest(changePassword.TRIGGER, changePasswordSaga),
    takeLatest(resetPasswordRequest.TRIGGER, resetPasswordRequestSaga),
    takeLatest(resetPassword.TRIGGER, resetPasswordSaga),
    takeLatest(verifySession.TRIGGER, verifyUserSessionSaga),
  ]);
}
