import { all, call, put, takeLatest } from 'redux-saga/effects';

// import { formatOutgoingHours } from 'utils/format/std-hours';
import SDK from 'utils/sdk';
import { listRoles } from './actions';

export function* listRolesSaga() {
  try {
    yield put(listRoles.request());
    const client = yield call([SDK, 'getClient']);
    const { data } = yield call([client, 'roleList']);
    yield put(listRoles.success(data));
  } catch (error) {
    yield put(listRoles.failure(error));
  } finally {
    yield put(listRoles.fulfill());
  }
}

export default function* rolesWatcher() {
  yield all([takeLatest(listRoles.TRIGGER, listRolesSaga)]);
}
