const PREFIX = 'INCOME_REPORT';
export const INCOME_REPORT_SET_ACTIVE_TAB = `${PREFIX}/SET_ACTIVE_TAB`;
export const INCOME_REPORT_CREATE = `${PREFIX}/CREATE`;
export const INCOME_REPORT_UPDATE = `${PREFIX}/UPDATE`;
export const INCOME_REPORT_DELETE = `${PREFIX}/DELETE`;
export const INCOME_REPORT_DOWNLOAD = `${PREFIX}/DOWNLOAD`;
export const INCOME_REPORT_PAGINATED_LIST = `${PREFIX}/PAGINATED_LIST`;
export const INCOME_REPORT_CONSOLIDATED = `${PREFIX}/CONSOLIDATED`;
export const INCOME_REPORT_SET_SELECTED = `${PREFIX}/SET_SELECTED`;
export const INCOME_REPORT_RESET_CONSOLIDATED = `${PREFIX}/RESET_CONSOLIDATED`;
export const INCOME_REPORT_SET_FILTERS = `${PREFIX}/SET_FILTERS`;
export const INCOME_REPORT_SHOW_EDIT_FORM = `${PREFIX}/SHOW_EDIT_FORM`;
export const INCOME_REPORT_CHECK_IF_EXISTS = `${PREFIX}/CHECK_IF_EXISTS`;
export const INCOME_REPORT_RECALCULATE = `${PREFIX}/RECALCULATE`;
