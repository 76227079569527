import { createAction } from 'redux-actions';
import {
  CLOSE_CHANGE_PASSWORD_MODAL,
  CLOSE_FORGOT_PASSWORD_MODAL,
  OPEN_CHANGE_PASSWORD_MODAL,
  OPEN_FORGOT_PASSWORD_MODAL,
  TOOGLE_DELETE_CONFIRMATION_MODAL,
  TOOGLE_EXISTS_INCOME_REPORT_MODAL,
  TOOGLE_IS_NEW_USER_FORM_MODAL,
  TOOGLE_LIST_MODAL,
  TOOGLE_REFRESH_CONFIRMATION_MODAL,
  TOOGLE_USER_DELETE_MODAL,
  TOOGLE_USER_FORM_MODAL,
} from './types';

export const openChangePasswordModal = createAction(OPEN_CHANGE_PASSWORD_MODAL);
export const closeChangePasswordModal = createAction(
  CLOSE_CHANGE_PASSWORD_MODAL
);
export const openForgotPasswordModal = createAction(OPEN_FORGOT_PASSWORD_MODAL);
export const closeForgotPasswordModal = createAction(
  CLOSE_FORGOT_PASSWORD_MODAL
);
export const toogleUserFormModal = createAction(TOOGLE_USER_FORM_MODAL);
export const toogleIsNewUserFormModal = createAction(
  TOOGLE_IS_NEW_USER_FORM_MODAL
);
export const toogleUserDeleteModal = createAction(TOOGLE_USER_DELETE_MODAL);
export const toogleExistsIncomeReportModal = createAction(
  TOOGLE_EXISTS_INCOME_REPORT_MODAL
);
export const toogleDeleteConfirmationModal = createAction(
  TOOGLE_DELETE_CONFIRMATION_MODAL
);
export const toogleRefreshConfirmationModal = createAction(
  TOOGLE_REFRESH_CONFIRMATION_MODAL
);
export const tooglListModal = createAction(TOOGLE_LIST_MODAL);
