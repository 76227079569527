import OpenAPIClientAxios from 'openapi-client-axios';
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { logoutUser } from 'modules/auth/actions';
import { store } from '../store';

const { REACT_APP_URL_SPEC, REACT_APP_API_BASE_URL } = process.env;

const SDK = {
  _api: null,
  _client: null,
  getApi: () => {
    if (!SDK._api) {
      throw new Error('SDK is not initialized.');
    }
    return SDK._api;
  },
  getClient: () => {
    if (!SDK._client) {
      throw new Error('SDK is not initialized.');
    }
    return SDK._client;
  },
  init: async () => {
    if (SDK._api && SDK._client) {
      return false;
    }

    try {
      const api = new OpenAPIClientAxios({
        definition: REACT_APP_URL_SPEC,
        axiosConfigDefaults: {
          baseURL: REACT_APP_API_BASE_URL,
          withCredentials: true,
          timeout: 5 * 60 * 1000,
        },
      });
      await api.init();

      const client = await api.getClient();
      client.interceptors.response.use(
        (response) => response,
        (error) => Promise.reject(SDK._buildError(error))
      );

      SDK._api = api;
      SDK._client = client;

      return client;
    } catch (error) {
      throw SDK._buildError(error);
    }
  },

  _buildError: (err) => {
    if (!err || !err.response) {
      const message = ['Service not available. Please try again.'];
      Object.assign(err, { errorMessages: message });
    } else if (
      err.response.status === 408 ||
      err.response.status === 'ECONNABORTED'
    ) {
      const message = ['The service timed out. Please try again.'];
      Object.assign(err, { errorMessages: message });
    } else if (err.response.status === 401) {
      store.dispatch(logoutUser());
      Object.assign(err, { errorMessages: [err.message] });
    } else if (
      err.response.data &&
      !err.response.ok &&
      err.response.status !== 401
    ) {
      Object.assign(err, {
        errorMessages: err.response.data.errors || [],
      });
    } else {
      Object.assign(err, { errorMessages: [] });
    }
    return err;
  },
};

export default SDK;
