import produce from 'immer';
import { listRoles } from './actions';
/* eslint-disable default-case */
/* eslint-disable no-param-reassign */

const initialState = {
  error: null,
  loading: false,
  roles: [],
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case listRoles.TRIGGER:
      draft.loading = true;
      draft.error = null;
      break;
    case listRoles.SUCCESS:
      draft.roles = payload;
      break;
    case listRoles.FAILURE:
      draft.error = payload;
      break;
    case listRoles.FULFILL:
      draft.loading = false;
      break;
  }
}, initialState);

export default reducer;
