import { createSelector } from 'reselect';
import { parseISO } from 'date-fns';

export const getDashboardState = (state) => state.incomeReport;

export const getIncomeReportActiveTab = createSelector(
  getDashboardState,
  ({ activeTab }) => activeTab
);

export const getIncomeReportsList = createSelector(
  getDashboardState,
  ({ reports }) => {
    return reports.map((report) => {
      const date = parseISO(report.date);
      return {
        ...report,
        date,
      };
    });
  }
);

export const getPaginationMetaDataIncomeReports = createSelector(
  getDashboardState,
  ({ paginationMetaData }) => paginationMetaData
);

export const getConsolidatedIncomeReport = createSelector(
  getDashboardState,
  ({ consolidatedReport }) => consolidatedReport
);

export const getSelectedConsolidatedIncomeReport = createSelector(
  getDashboardState,
  ({ selectedReport }) => selectedReport
);

export const getIncomeReportFilters = createSelector(
  getDashboardState,
  ({ filters }) => filters
);

export const getIncomeReportShowEditForm = createSelector(
  getDashboardState,
  ({ showEditForm }) => showEditForm
);
export const getIncomeMissedReports = createSelector(
  getDashboardState,
  ({ missedReports }) => missedReports
);

export const getIncomeReportLoading = createSelector(
  getDashboardState,
  ({ loading }) => loading
);
