import { useEffect, useState } from 'react';

import SDK from 'utils/sdk';

const SdkLoader = ({ children }) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    async function init() {
      await SDK.init();
      setReady(true);
    }
    init();
  }, []);

  if (!ready) return null;

  return children;
};

export default SdkLoader;
