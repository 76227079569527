import auth from 'modules/auth/reducer';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import dashboard from 'modules/dashboards/reducer';
import { reducer as form } from 'redux-form';
import gyms from 'modules/gyms/reducer';
import holidayHours from 'modules/holiday-hours/reducer';
import incomeReport from 'modules/income-report/reducer';
import { logoutUser } from 'modules/auth/actions';
import me from 'modules/me/reducer';
import modal from 'modules/modal/reducer';
import reports from 'modules/reports/reducer';
import roles from 'modules/roles/reducer';
import settings from 'modules/settings/reducer';
import sku from 'modules/sku-manager/reducer';
import stdHours from 'modules/std-hours/reducer';
import users from 'modules/users/reducer';
import routerHistory from './history';

const appReducer = combineReducers({
  // external
  form,
  router: connectRouter(routerHistory),

  // app
  auth,
  me,
  stdHours,
  holidayHours,
  gyms,
  users,
  roles,
  dashboard,
  sku,
  reports,
  modal,
  settings,
  incomeReport,
});

const actions = [logoutUser.SUCCESS];

const rootReducer = (state, action) => {
  if (actions.includes(action.type)) {
    const { router } = state;
    return appReducer({ router }, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
