import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';

import { createAction } from 'redux-actions';
import {
  AUTH_CHANGE_PASSWORD,
  AUTH_RESET_PASSWORD,
  AUTH_RESET_PASSWORD_REQUEST,
  AUTH_SHOW_CONFIRM_PASSWORD,
  AUTH_SHOW_NEW_PASSWORD,
  AUTH_SHOW_PASSWORD,
  AUTH_VERIFY_SESSION,
  LOGIN_USER,
  LOGOUT_USER,
} from './types';

export const loginUser = createRoutine(LOGIN_USER);
export const changePassword = createRoutine(AUTH_CHANGE_PASSWORD);
export const resetPasswordRequest = createRoutine(AUTH_RESET_PASSWORD_REQUEST);
export const resetPassword = createRoutine(AUTH_RESET_PASSWORD);
export const logoutUser = createRoutine(LOGOUT_USER);
export const verifySession = createRoutine(AUTH_VERIFY_SESSION);

export const showPassword = createAction(AUTH_SHOW_PASSWORD);
export const showNewPassword = createAction(AUTH_SHOW_NEW_PASSWORD);
export const showConfirmPassword = createAction(AUTH_SHOW_CONFIRM_PASSWORD);

export const bindedChangePassword = bindRoutineToReduxForm(changePassword);
export const bindedResetPasswordRequest =
  bindRoutineToReduxForm(resetPasswordRequest);
export const bindedResetPassword = bindRoutineToReduxForm(resetPassword);
export const bindedLoginUser = bindRoutineToReduxForm(loginUser);
