import { createSelector } from 'reselect';

export const getSettings = (state) => state.settings;
export const isSettingsLoading = createSelector(
  getSettings,
  ({ loading }) => loading
);
export const getSettingsError = createSelector(
  getSettings,
  ({ error }) => error
);

export const getSettingsItems = createSelector(
  getSettings,
  ({ items }) => items
);

export const getLocation = createSelector(
  getSettings,
  ({ location }) => location
);
