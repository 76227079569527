import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { SubmissionError } from 'redux-form';
// import camelcaseKeys from 'camelcase-keys';
// import { retrieveMe } from 'modules/me/actions';
import {
  toogleUserDeleteModal,
  toogleUserFormModal,
} from 'modules/modal/actions';

// import { formatOutgoingHours } from 'utils/format/std-hours';
import SDK from 'utils/sdk';
// import { getGymItem } from 'modules/gyms/selectors';
import { getGymItem } from 'modules/gyms/selectors';
import {
  createUSer,
  deleteUser,
  getUser,
  listUsers,
  updateUser,
  updateUserStatus,
} from './actions';

export function* listUsersSaga() {
  try {
    const location = yield select(getGymItem);
    const queryParams = location ? { locationId: location } : null;
    yield put(listUsers.request());
    const client = yield call([SDK, 'getClient']);
    const { data } = yield call([client, 'userList'], queryParams);
    yield put(listUsers.success(data));
  } catch (error) {
    yield put(listUsers.failure(error));
  } finally {
    yield put(listUsers.fulfill());
  }
}

export function* getUserSaga({ payload }) {
  try {
    yield put(getUser.request());
    const client = yield call([SDK, 'getClient']);
    const { data } = yield call([client, 'userOne'], { id: payload });
    yield put(getUser.success(data));
  } catch (error) {
    yield put(getUser.failure(error));
  } finally {
    yield put(getUser.fulfill());
  }
}

export function* updateUserSaga({ payload }) {
  try {
    const { id, locations, roles, ...rest } = payload;
    const roleIds = Object.values(roles).filter((role) => role);
    const locationIds = Object.values(locations).filter((location) => location);
    yield put(updateUser.request());
    const client = yield call([SDK, 'getClient']);
    yield call(
      [client, 'userUpdate'],
      { id },
      { ...rest, locations: locationIds, roles: roleIds }
    );
    yield put(listUsers());
    yield put(toogleUserFormModal(false));
    yield put(updateUser.success());
    toast.success('User successfully updated');
  } catch (error) {
    const data = error?.response?.data?.message?.toString();
    yield put(updateUser.failure(new SubmissionError({ _error: data })));
    toast.error(data);
  } finally {
    yield put(updateUser.fulfill());
  }
}

export function* toggleUserStatusSaga({ payload }) {
  try {
    yield put(updateUserStatus.request());
    const { is_active: isActive, userId } = payload;
    const client = yield call([SDK, 'getClient']);
    const { data } = yield call(
      [client, 'userUpdate'],
      { id: userId },
      { isActive }
    );
    if (data) {
      yield put(listUsers());
      yield put(updateUserStatus.success());
    } else {
      yield put(updateUserStatus.failure(data.advisory));
    }
  } catch (error) {
    yield put(updateUserStatus.failure(error));
  } finally {
    yield put(updateUserStatus.fulfill());
  }
}

export function* createUserSaga({ payload }) {
  try {
    const { locations, roles, ...rest } = payload;
    const roleIds = Object.values(roles);
    const locationIds = locations
      ? Object.values(locations).filter((location) => location)
      : null;
    yield put(createUSer.request());
    const client = yield call([SDK, 'getClient']);
    yield call([client, 'userCreate'], null, {
      ...rest,
      locations: locationIds,
      roles: roleIds,
    });
    yield put(listUsers());
    yield put(toogleUserFormModal(false));
    yield put(createUSer.success());
  } catch (error) {
    yield put(createUSer.failure(error));
  } finally {
    yield put(createUSer.fulfill());
  }
}

export function* deleteUserSaga({ payload }) {
  try {
    yield put(deleteUser.request());
    const client = yield call([SDK, 'getClient']);
    yield call([client, 'userDelete'], { id: payload });
    yield put(listUsers());
    yield put(toogleUserDeleteModal(false));
    yield put(deleteUser.success());
  } catch (error) {
    yield put(deleteUser.failure(error));
  } finally {
    yield put(deleteUser.fulfill());
  }
}

export default function* usersWatcher() {
  yield all([
    takeLatest(listUsers.TRIGGER, listUsersSaga),
    takeLatest(getUser.TRIGGER, getUserSaga),
    takeLatest(updateUser.TRIGGER, updateUserSaga),
    takeLatest(updateUserStatus.TRIGGER, toggleUserStatusSaga),
    takeLatest(createUSer.TRIGGER, createUserSaga),
    takeLatest(deleteUser.TRIGGER, deleteUserSaga),
  ]);
}
