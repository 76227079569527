import produce from 'immer';
import {
  changePassword,
  loginUser,
  logoutUser,
  resetPassword,
  resetPasswordRequest,
  showConfirmPassword,
  showNewPassword,
  showPassword,
  verifySession,
} from './actions';

/* eslint-disable default-case */
/* eslint-disable no-param-reassign */

const initialState = {
  loading: false,
  error: null,
  isLoggedIn: false,
  showPassword: false,
  showNewPassword: false,
  showConfirmPassword: false,
  ability: null,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case loginUser.TRIGGER:
    case logoutUser.TRIGGER:
    case changePassword.TRIGGER:
    case resetPasswordRequest.TRIGGER:
    case resetPassword.TRIGGER:
      draft.loading = true;
      draft.error = null;
      break;
    case verifySession.SUCCESS:
    case loginUser.SUCCESS:
      draft.isLoggedIn = true;
      draft.ability = payload;
      break;
    case logoutUser.SUCCESS:
      draft.isLoggedIn = false;
      draft.ability = null;
      break;
    case loginUser.FAILURE:
    case logoutUser.FAILURE:
    case changePassword.FAILURE:
    case resetPasswordRequest.FAILURE:
    case resetPassword.FAILURE:
      draft.error = payload;
      break;
    case loginUser.FULFILL:
    case logoutUser.FULFILL:
    case changePassword.FULFILL:
    case resetPasswordRequest.FULFILL:
    case resetPassword.FULFILL:
      draft.loading = false;
      break;

    case showPassword.toString():
      draft.showPassword = payload;
      break;
    case showNewPassword.toString():
      draft.showNewPassword = payload;
      break;
    case showConfirmPassword.toString():
      draft.showConfirmPassword = payload;
      break;
  }
}, initialState);

export default reducer;
