/* eslint-disable camelcase */
/* eslint-disable radix */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import SDK from 'utils/sdk';
import { toast } from 'react-toastify';
import { runSettingsByLocation, settingsByLocationList } from './actions';

import { getSettingsItems } from './selectors';

export function* runSettingsByLocationSaga({ payload: { values } }) {
  try {
    yield put(runSettingsByLocation.request());

    const settingsItems = yield select(getSettingsItems);

    const settings = settingsItems.filter(
      ({ location_id }) => location_id === values.locationId
    )[0];
    const settingsExists = !!settings;

    const requestBody = {
      royalty: parseFloat(values.royalty),
      brand_fund: parseFloat(values.brandFund),
      technology_fund: parseFloat(values.technologyFund),
      destination_emails: values.destinationEmails.replace(' ', '').split(','),
      location_id: values.locationId,
      workout_emails: values.workoutEmails.replace(' ', '').split(','),
    };

    const client = yield call([SDK, 'getClient']);
    const { data } = yield call(
      [
        client,
        settingsExists ? 'locationSettingsUpdate' : 'locationSettingsCreate',
      ],
      settingsExists ? values.id : null,
      requestBody
    );

    yield put(runSettingsByLocation.success(data));
    yield put(settingsByLocationList());
    toast.success('Settings saved');
  } catch (error) {
    const data = error?.response?.data?.message?.toString();
    yield put(runSettingsByLocation.failure(data));
    toast.error('There was an error when trying to save the settings');
  } finally {
    yield put(runSettingsByLocation.fulfill());
  }
}

export function* settingsByLocationListSaga() {
  try {
    yield put(settingsByLocationList.request());

    const client = yield call([SDK, 'getClient']);
    const { data } = yield call([client, 'locationSettingsList']);

    yield put(settingsByLocationList.success(data));
  } catch (error) {
    const data = error?.response?.data?.message?.toString();
    yield put(settingsByLocationList.failure(data));
  } finally {
    yield put(settingsByLocationList.fulfill());
  }
}

export default function* settingsWatcher() {
  yield all([
    takeLatest(runSettingsByLocation.TRIGGER, runSettingsByLocationSaga),
    takeLatest(settingsByLocationList.TRIGGER, settingsByLocationListSaga),
  ]);
}
