import produce from 'immer';
import {
  closeChangePasswordModal,
  closeForgotPasswordModal,
  openChangePasswordModal,
  openForgotPasswordModal,
  tooglListModal,
  toogleDeleteConfirmationModal,
  toogleExistsIncomeReportModal,
  toogleIsNewUserFormModal,
  toogleRefreshConfirmationModal,
  toogleUserDeleteModal,
  toogleUserFormModal,
} from './actions';

/* eslint-disable no-param-reassign */

const initialState = {
  openChangePassword: false,
  openForgotPassword: false,
  openUserFormModal: false,
  openUserDeleteModal: false,
  isNewUserFormModal: false,
  openExistsIncomeReportModal: false,
  openDeleteConfirmationModal: false,
  openRefreshConfirmationModal: false,
  openListModal: false,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case openChangePasswordModal.toString():
      draft.openChangePassword = true;
      break;
    case closeChangePasswordModal.toString():
      draft.openChangePassword = false;
      break;
    case openForgotPasswordModal.toString():
      draft.openForgotPassword = true;
      break;
    case closeForgotPasswordModal.toString():
      draft.openForgotPassword = false;
      break;
    case toogleUserFormModal.toString():
      draft.openUserFormModal = payload;
      break;
    case toogleIsNewUserFormModal.toString():
      draft.isNewUserFormModal = payload;
      break;
    case toogleUserDeleteModal.toString():
      draft.openUserDeleteModal = payload;
      break;
    case toogleExistsIncomeReportModal.toString():
      draft.openExistsIncomeReportModal = payload;
      break;
    case toogleDeleteConfirmationModal.toString():
      draft.openDeleteConfirmationModal = payload;
      break;
    case toogleRefreshConfirmationModal.toString():
      draft.openRefreshConfirmationModal = payload;
      break;
    case tooglListModal.toString():
      draft.openListModal = payload;
      break;
    // no default
  }
}, initialState);

export default reducer;
