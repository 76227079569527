import { createRoutine, bindRoutineToReduxForm } from 'redux-saga-routines';
import {
  LIST_REPORTS,
  LIST_REPORT_LOG,
  LIST_SCHEDULED,
  RUN_REPORT,
  CREATE_SCHEDULE_REPORT,
  UPDATE_SCHEDULE_REPORT,
  DOWNLOAD_REPORT,
} from './types';

export const listReports = createRoutine(LIST_REPORTS);
export const listReportLog = createRoutine(LIST_REPORT_LOG);
export const listScheduled = createRoutine(LIST_SCHEDULED);
export const runReport = createRoutine(RUN_REPORT);
export const downloadReport = createRoutine(DOWNLOAD_REPORT);
export const createSchedule = createRoutine(CREATE_SCHEDULE_REPORT);
export const updateSchedule = createRoutine(UPDATE_SCHEDULE_REPORT);

export const bindedRunReport = bindRoutineToReduxForm(runReport);
export const bindedCreateSchedule = bindRoutineToReduxForm(createSchedule);
export const bindedUpdateSchedule = bindRoutineToReduxForm(updateSchedule);
