const PREFIX = 'MODAL/';

// Change Password Modal
export const OPEN_CHANGE_PASSWORD_MODAL = `${PREFIX}OPEN_CHANGE_PASSWORD_MODAL`;
export const CLOSE_CHANGE_PASSWORD_MODAL = `${PREFIX}CLOSE_CHANGE_PASSWORD_MODAL`;
export const OPEN_FORGOT_PASSWORD_MODAL = `${PREFIX}OPEN_FORGOT_PASSWORD_MODAL`;
export const CLOSE_FORGOT_PASSWORD_MODAL = `${PREFIX}CLOSE_FORGOT_PASSWORD_MODAL`;
export const TOOGLE_USER_FORM_MODAL = `${PREFIX}USER_FORM_MODAL`;
export const TOOGLE_IS_NEW_USER_FORM_MODAL = `${PREFIX}IS_NEW_USER_FORM_MODAL`;
export const TOOGLE_USER_DELETE_MODAL = `${PREFIX}USER_DELETE_MODAL`;
export const TOOGLE_EXISTS_INCOME_REPORT_MODAL = `${PREFIX}EXIST_INCOME_REPORT_MODAL`;
export const TOOGLE_DELETE_CONFIRMATION_MODAL = `${PREFIX}DELETE_CONFIRMATION_MODAL`;
export const TOOGLE_REFRESH_CONFIRMATION_MODAL = `${PREFIX}REFRESH_CONFIRMATION_MODAL`;
export const TOOGLE_LIST_MODAL = `${PREFIX}LIST_MODAL`;
