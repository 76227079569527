const parseData = (values) => {
  const formData = new FormData();
  Object.entries(values).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return formData;
};

export const clearResponse = ({ success, advisory, ...data }) => data;

export default parseData;
