const PREFIX = 'AUTH/';
export const LOGIN_USER = `${PREFIX}LOGIN`;
export const LOGOUT_USER = `${PREFIX}LOGOUT`;

export const AUTH_CHANGE_PASSWORD = `${PREFIX}CHANGE_PASSWORD`;
export const AUTH_RESET_PASSWORD_REQUEST = `${PREFIX}RESET_PASSWORD_REQUEST`;
export const AUTH_RESET_PASSWORD = `${PREFIX}RESET_PASSWORD`;
export const AUTH_VERIFY_SESSION = `${PREFIX}VERIFY_SESSION`;

export const AUTH_SHOW_PASSWORD = `${PREFIX}SHOW_PASSWORD`;
export const AUTH_SHOW_NEW_PASSWORD = `${PREFIX}SHOW_NEW_PASSWORD`;
export const AUTH_SHOW_CONFIRM_PASSWORD = `${PREFIX}SHOW_CONFIRM_PASSWORD`;
