import { all, call, put, takeLatest } from 'redux-saga/effects';
import SDK from 'utils/sdk';
import { changeGym, listMeGyms } from './actions';

export function* listMeGymsSaga() {
  try {
    yield put(listMeGyms.request());
    const client = yield call([SDK, 'getClient']);
    const { data: response } = yield call([client, 'locationsList']);

    if (response.success) {
      yield put(listMeGyms.success(response.data));
    } else {
      yield put(listMeGyms.failure(response.error));
    }
  } catch (error) {
    yield put(listMeGyms.failure(error));
  } finally {
    yield put(listMeGyms.fulfill());
  }
}

export function* changeGymSaga({ payload }) {
  try {
    yield put(changeGym.request());
    yield put(changeGym.success(payload));
  } catch (error) {
    yield put(changeGym.failure(error));
  } finally {
    yield put(changeGym.fulfill());
  }
}

export default function* gymsWatcher() {
  yield all([
    takeLatest(listMeGyms.TRIGGER, listMeGymsSaga),
    takeLatest(changeGym.TRIGGER, changeGymSaga),
  ]);
}
