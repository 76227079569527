import { createSelector } from 'reselect';

export const getDashboardState = (state) => state.dashboard;

export const getDashboardActiveTab = createSelector(
  getDashboardState,
  ({ activeTab }) => activeTab
);
export const getDashboardFilters = createSelector(
  getDashboardState,
  ({ filters }) => filters
);
export const isOpenDatepickerPopover = createSelector(
  getDashboardState,
  ({ showDatepickerPopover }) => showDatepickerPopover
);

export const getFirstTimerGraphDataset = createSelector(
  getDashboardState,
  ({ firstTimersGraphData }) => firstTimersGraphData.graphDatasets
);

export const getAttendaceGraphDataset = createSelector(
  getDashboardState,
  ({ attendanceGraphData }) => attendanceGraphData.graphDatasets
);
export const getMembershiphRevenueGraphDataset = createSelector(
  getDashboardState,
  ({ membershipRevenueGraphData }) => membershipRevenueGraphData.graphDatasets
);
export const getActiveMembershiphsGraphDataset = createSelector(
  getDashboardState,
  ({ activeMembershipsGraphData }) => activeMembershipsGraphData.graphDatasets
);
export const getAssideFirstTimersGraph = createSelector(
  getDashboardState,
  ({ assideFirstTimersGraph }) => assideFirstTimersGraph
);
export const getAssideAttendaceGraph = createSelector(
  getDashboardState,
  ({ assideAttendanceGraph }) => assideAttendanceGraph
);
export const getAssideMembershiphRevenueGraph = createSelector(
  getDashboardState,
  ({ assideMembershipRevenueGraph }) => assideMembershipRevenueGraph
);
export const getAssideActiveMembershiphGraph = createSelector(
  getDashboardState,
  ({ assideActiveMembershipsGraph }) => assideActiveMembershipsGraph
);
export const getGymsPerformance = createSelector(
  getDashboardState,
  ({ gymsPerformance }) => gymsPerformance
);
export const getFirstTimersRanking = createSelector(
  getDashboardState,
  ({ firstTimersRanking }) => firstTimersRanking
);
export const getClassAttendanceRanking = createSelector(
  getDashboardState,
  ({ classAttendanceRanking }) => classAttendanceRanking
);
export const getActiveMembershipsRanking = createSelector(
  getDashboardState,
  ({ activeMembershipsRanking }) => activeMembershipsRanking
);
export const getMembershipsRevenueRanking = createSelector(
  getDashboardState,
  ({ membershipsRevenueRanking }) => membershipsRevenueRanking
);

export const getResumeData = createSelector(
  getDashboardState,
  ({
    filters,
    firstTimersGraphData,
    attendanceGraphData,
    membershipRevenueGraphData,
    activeMembershipsGraphData,
    gymsPerformance,
  }) => {
    const { locations } = filters;
    const { doingBad } = gymsPerformance;
    if (!locations.length) return null;
    const resumeData = [];
    locations.forEach((location) => {
      const locationId = location.value;
      const firstTimers = firstTimersGraphData.graphDatasets.find(
        (item) => item.id === locationId
      );
      const attendance = attendanceGraphData.graphDatasets.find(
        (item) => item.id === locationId
      );
      const membershipRevenue = membershipRevenueGraphData.graphDatasets.find(
        (item) => item.id === locationId
      );
      const activeMemberships = activeMembershipsGraphData.graphDatasets.find(
        (item) => item.id === locationId
      );
      const gymPerformance = doingBad.find(
        (item) => item.location.id === locationId
      );
      resumeData.push({
        locationId,
        locationName: location.label,
        doingWell: !gymPerformance,
        firstTimers: firstTimers ? firstTimers.growthFeedback : null,
        attendance: attendance ? attendance.growthFeedback : null,
        membershipRevenue: membershipRevenue
          ? membershipRevenue.growthFeedback
          : null,
        activeMemberships: activeMemberships
          ? activeMemberships.growthFeedback
          : null,
      });
    });
    return resumeData;
  }
);
export const isLoadingFirstTimersGraphData = createSelector(
  getDashboardState,
  ({ loadingFirstTimersGraphData }) => loadingFirstTimersGraphData
);

export const isLoadingAttendanceGraphData = createSelector(
  getDashboardState,
  ({ loadingAttendanceGraphData }) => loadingAttendanceGraphData
);

export const isLoadingMembershipRevenueGraphData = createSelector(
  getDashboardState,
  ({ loadingMembershipRevenueGraphData }) => loadingMembershipRevenueGraphData
);

export const isLoadingActiveMembershipsGraphData = createSelector(
  getDashboardState,
  ({ loadingActiveMembershipsGraphData }) => loadingActiveMembershipsGraphData
);
