/* eslint-disable import/no-cycle */
import { all, call } from 'redux-saga/effects';

import authWatch from 'modules/auth/sagas';
import dashboardWatcher from 'modules/dashboards/sagas';
import gymsWatch from 'modules/gyms/sagas';
import holidayHoursWatcher from 'modules/holiday-hours/sagas';
import incomeReportWatcher from 'modules/income-report/sagas';
import meWatch from 'modules/me/sagas';
import reportsWatcher from 'modules/reports/sagas';
import rolesWatcher from 'modules/roles/sagas';
/* eslint-disable no-useless-catch */
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import settingsWatcher from 'modules/settings/sagas';
import skusWatcher from 'modules/sku-manager/sagas';
import stdHoursWatcher from 'modules/std-hours/sagas';
import usersWatcher from 'modules/users/sagas';

function* rootSaga() {
  try {
    yield all([
      // external
      call(routinePromiseWatcherSaga),

      // app
      call(authWatch),
      call(meWatch),
      call(stdHoursWatcher),
      call(gymsWatch),
      call(usersWatcher),
      call(rolesWatcher),
      call(holidayHoursWatcher),
      call(dashboardWatcher),
      call(skusWatcher),
      call(reportsWatcher),
      call(settingsWatcher),
      call(incomeReportWatcher),
    ]);
  } catch (error) {
    throw error;
  }
}

export default rootSaga;
