/* eslint-disable react/prop-types */
import React from 'react';
import classnames from 'classnames';

const ScreenLoading = ({ minHeight = false, children }) => (
  <div>
    <div
      className={classnames('main--loader-dual-ring', {
        'min-height': minHeight,
      })}
    />
    {children ? <div className="main--loader-children">{children}</div> : null}
  </div>
);

export default ScreenLoading;
